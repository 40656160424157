import React, { useState, useEffect } from "react";
import {
  listBrand,
  addBrand,
  editBrand,
} from "../Apis/AuthenticatedDataService";
import PaginatedItems from "../pagination/pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoAddCircleSharp, IoTrashOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import moment from "moment";
const Brand = () => {
  const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const [brand, setBrand] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");



  

  const getData = async () => {
    const getBrand = await listBrand();
    setBrand(getBrand.data);
  };

  const fetchdata = (start, end) => {
    setSkip(end);
    setLimit(start);
    getData();
  };

  const onClickEdit = async (values) => {
    let formData = new FormData();

    // Update the formData object
    formData.append("image", values.file);
    formData.append("id", brand._id);
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("mobile", values.mobile);
    formData.append("email", values.email);
    formData.append("facebook", values.facebook);
    formData.append("youtube", values.youtube);
    formData.append("instagram", values.instagram);
    formData.append("twitter", values.twitter);
    formData.append("copyright", values.copyright);

    const response = await editBrand(formData);
    console.log(response);
    ToastSuccessNotifications(response.message);
    
    getData();
  };

  const onClickCreate = async (values) => {
    let formData = new FormData();

    // Update the formData object
    formData.append("image", values.file);
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("mobile", values.mobile);
    formData.append("email", values.email);
    formData.append("facebook", values.facebook);
    formData.append("youtube", values.youtube);
    formData.append("instagram", values.instagram);
    formData.append("twitter", values.twitter);
    formData.append("copyright", values.copyright);

    const response = await addBrand(formData);
    console.log(response);
    ToastSuccessNotifications(response.message);
   
    getData();
  };

  const addvalidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number is invalid")
      .required("Mobile is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    facebook: Yup.string()
      .url("Facebook URL is invalid")
      .required("Facebook is required"),
    youtube: Yup.string()
      .url("Youtube URL is invalid")
      .required("Youtube is required"),
    twitter: Yup.string()
      .url("Twitter URL is invalid")
      .required("Twitter is required"),
    instagram: Yup.string()
      .url("Instagram URL is invalid")
      .required("Instagram is required"),
    copyright: Yup.string()
      .required("copyright is required"),
      file: Yup.mixed()
      .required('Please select an image file')
      .test('fileSize', 'File size must be less than 1 MB', (value) => {
        if (!value) return true; // not required, so no validation necessary
        return value.size <= 1024 * 1024; // 1 MB in bytes
      })
  });

  const editvalidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number is invalid")
      .required("Mobile is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    facebook: Yup.string()
      .url("Facebook URL is invalid")
      .required("Facebook is required"),
    youtube: Yup.string()
      .url("Youtube URL is invalid")
      .required("Youtube is required"),
    twitter: Yup.string()
      .url("Twitter URL is invalid")
      .required("Twitter is required"),
    instagram: Yup.string()
      .url("Instagram URL is invalid")
      .required("Instagram is required"),
    copyright: Yup.string()
      .required("copyright is required"),
      file: Yup.mixed()
      .test('fileSize', 'File size must be less than 1 MB', (value) => {
        if (!value) return true; // not required, so no validation necessary
        return value.size <= 1024 * 1024; // 1 MB in bytes
      })
  });

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="relative overflow-x-auto mx-8">
      <div className="flex justify-center mb-3">
          <div
            className="text-yellow bg-bloodred w-full rounded-lg text-center"
            style={{ fontSize: "30px", fontWeight: "700" }}
          >
            Brand
          </div>
        </div>
      <Formik
        initialValues={
          brand != null
            ? {
                name: brand.name,
                address: brand.address,
                mobile: brand.mobile,
                email: brand.email,
                facebook: brand.facebook,
                youtube: brand.youtube,
                instagram: brand.instagram,
                logo: "",
                twitter: brand.twitter,
                copyright: brand.copyright,
                file:""
              }
            : {
                name: "",
                address: "",
                mobile: "",
                email: "",
                facebook: "",
                youtube: "",
                instagram: "",
                logo: "",
                twitter: "",
                copyright: "",
                file:""
              }
        }
        enableReinitialize={true}
        validationSchema={brand == null ?addvalidationSchema:editvalidationSchema}
        onSubmit={brand == null ? (values) => {
          onClickCreate(values);
        }:(values) => {
          onClickEdit(values);
        } }
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <div className="flex">
              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Name
                  </label>
                  <Field
                    name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.name && touched.name && (
                    <span className="text-danger">{errors.name}</span>
                  )}
                </div>
              </div>

              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Address
                  </label>
                  <Field
                    name="address"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.address && touched.address && (
                    <span className="text-danger">{errors.address}</span>
                  )}
                </div>
              </div>

              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Mobile
                  </label>
                  <Field
                    name="mobile"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.mobile && touched.mobile && (
                    <span className="text-danger">{errors.mobile}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email
                  </label>
                  <Field
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.email && touched.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </div>
              </div>

              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Facebook
                  </label>
                  <Field
                    name="facebook"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.facebook && touched.facebook && (
                    <span className="text-danger">{errors.facebook}</span>
                  )}
                </div>
              </div>

              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Youtube
                  </label>
                  <Field
                    name="youtube"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.youtube && touched.youtube && (
                    <span className="text-danger">{errors.youtube}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Instagram
                  </label>
                  <Field
                    name="instagram"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.instagram && touched.instagram && (
                    <span className="text-danger">{errors.instagram}</span>
                  )}
                </div>
              </div>

              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Twitter
                  </label>
                  <Field
                    name="twitter"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.twitter && touched.twitter && (
                    <span className="text-danger">{errors.twitter}</span>
                  )}
                </div>
              </div>

              <div className="w-1/3 mx-2">
                <div className="mb-4 ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Copyright
                  </label>
                  <Field
                    name="copyright"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                  />
                </div>
                <div className="mb-2">
                  {errors.copyright && touched.copyright && (
                    <span className="text-danger">{errors.copyright}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-3">
              {brand != null &&  <img src={`${backendUrl}/Brandlogo/${brand.logo}`}  style={{width:'300px',height:'180px'}} alt="Logo" /> }
             
            </div>

            <div className="flex justify-center">
              <div className="mb-4 ">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Logo
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(event) => {
                    setFieldValue("file", event.target.files[0]);
                  }}
                  className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                  placeholder=""
                />
                <div className="mb-2">
                  {errors.file && touched.file && (
                    <span className="text-danger">{errors.file}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-center text-center">
              <button
                type="submit"
                className="btn border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90"
              >
                {brand == null ? "Add" : "Update"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Brand;
