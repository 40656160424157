import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastSuccessNotifications } from "../../Notifications/ToastNotifications";
import { sendContactUsRequest } from "../../Apis/DataService";

const ContactUs = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      await sendContactUsRequest(values);
      ToastSuccessNotifications("Message Sent Successfully");
      toast.success("Message Sent Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      resetForm({
        name: "",
        email: "",
        message: "",
      });
    },
  });

  useEffect(() => {}, []);
  return (
    <div className="container mx-auto">
      <div className=" mt-3">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Get In Touch , We'd Love To Hear From You
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "350px" }}
              >
                
              </h5>
            </div>
            <img
              className="place-self-end w-80"
              alt=""
              src="./public/designinspirationamico.svg"
            />
          </div>
        </div>

        <div className="flex justify-center my-16">
          <div style={{ width: "60%" }}>
            <h5 className="md:text-5xl text-2xl font-bold prtextcolor  text-center my-3">
              Contact Us
            </h5>
            <h5 className="text-base font-normal text-black  text-center my-3">
              Got a technical issue? Want to send feedback about a feature? Need
              details about our pricing? Let us know.{" "}
            </h5>
            <hr />
            <form onSubmit={formik.handleSubmit} className="my-3">
              <div className="my-6">
                <label
                  htmlFor="name"
                  className="text-black font-semibold text-xl "
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-500">{formik.errors.name}</div>
                ) : null}
              </div>

              <div className="my-6">
                <label
                  htmlFor="email"
                  className="text-black font-semibold text-xl "
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="my-6">
                <label
                  htmlFor="message"
                  className="text-black font-semibold text-xl "
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
                {formik.touched.message && formik.errors.message ? (
                  <div className="text-red-500">{formik.errors.message}</div>
                ) : null}
              </div>

<div className="flex justify-center">
<button
                type="submit"
                className="rounded-lg bg-green-600  px-3 py-2 text-white  text-base font-semibold h-10 mt-3"
              >
                Submit
              </button>
</div>
             
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
