import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
const Invoice = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ATM</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto ">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Access Cash Anytime, Anywhere With Payripe’s Convenient ATM
                Feature
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                {" "}
                Experience The Convenience Of Withdrawing Cash From Your Account
                Anytime, Anywhere With Our Integrated ATM Feature.
              </h5>
              <a href="https://pg.payripe.in/register" target="_blank">
              <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
              </button>
              </a>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/atmmachineamico.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
               Convenient Cash Withdrawals With Payripe
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                {" "}
                With Our Vast ATM Network, You Can Easily Access Cash Anytime And Anywhere, Providing You With The Flexibility And Convenience You Need For Your Daily Transactions.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
                  Extensive Network Of ATMs
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                  Highly Secure Transactions
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                  Real-Time Updates
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                 Ability To Locate Nearby ATMs
                </h5>
              </div>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/creditcardrafiki.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img className="" alt="" src="./public/creditcardcuate.svg" />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                24/7 Availability For Your Cash Needs
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                {" "}
                Our Payment Gateway's ATM Feature Provides You With Easy Access To Cash At Any Time Of Day Or Night, With A Network Of Secure And User-Friendly Machines Available Globally To Meet Your Cash Withdrawal Needs.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
                  Round-The-Clock Access
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                  No Limitation On The Time Or Location
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                 Access To A Vast Network Of ATMs
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                  Quick Cash Withdrawal
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
