import React, { useState, useEffect } from "react";
import {
  getBlogs,
  deleteBlog,
  findBlog,
} from "../Apis/AuthenticatedDataService";
import PaginatedItems from "../pagination/pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoAddCircleSharp, IoTrashOutline } from "react-icons/io5";
import { FaEdit,FaBlogger } from "react-icons/fa";
import moment from "moment";
import { Link } from "react-router-dom";

const Blogs = () => {
  const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const [articles, setArticles] = useState("");
  const [blogs, setBlogs] = useState("");
  const [blog, setBlog] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState("");
  const [search, setSearch] = useState("");
  const [catId, setCatId] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editCatId, setEditCatId] = useState("");
  const [editStateId, setStateId] = useState("");
  const [editUrl, setEditUrl] = useState("");

  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const edithandleClose = () => setEditShow(false);
  const edithandleShow = () => setEditShow(true);
  const createhandleClose = () => setCreateShow(false);
  const createhandleShow = () => setCreateShow(true);

  const getData = async (skip, limit, search) => {
    const fetchBlogs = await getBlogs(skip, limit, search);
    setBlogs(fetchBlogs.data);
    setCount(fetchBlogs.count);
  };

  const fetchdata = (start, end) => {
    setSkip(end);
    setLimit(start);
    getData(end, start, search);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    getData(0, limit, e.target.value);
  };

  const setDeleteModal = (id) => {
    setCatId(id);
    handleShow();
  };

  const setEditModal = async (item) => {
    console.log(item);
    const fetchBlog = await findBlog(item);
    console.log(fetchBlog.data)
    setBlog(fetchBlog.data)
    edithandleShow();
  };

  const onClickEdit = async (values) => {
    const EditArticle = await deleteBlog(catId, values);
    ToastSuccessNotifications(EditArticle.message);
    edithandleClose();
    getData(skip, limit, search);
  };

  const onClickDelete = async () => {
    const deleteCategory = await deleteBlog(catId);
    ToastSuccessNotifications(deleteCategory.message);
    handleClose();
    getData(skip, limit, search);
  };

  const onClickCreate = async (values) => {
    getData(skip, limit, search);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    category: Yup.string().required("Category is required"),
    state: Yup.string().required("State is required"),
    url: Yup.string().required("URL is required"),
  });

  useEffect(() => {
    getData(skip, limit, search);
  }, []);
  return (
    <div>
      <div className="relative overflow-x-auto mx-8">
        <div className="flex justify-center">
          <div
            className="text-yellow bg-bloodred w-full rounded-lg text-center"
            style={{ fontSize: "30px", fontWeight: "700" }}
          >
            Blogs
          </div>
        </div>
        <div className="flex justify-between">
          <input
            type="text"
            onChange={(e) => {
              let timer;
              clearTimeout(timer);

              // set a new timeout to execute the function after 500ms
              timer = setTimeout(() => {
                onChangeSearch(e);
              }, 1000);
            }}
            className="bg-gray-50 border w-80 border-gray-300 my-3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 "
            placeholder="search"
          />
          <Link to="/addblog" className="btn-yellow h-10 mt-3 px-5 py-2">
            Add
          </Link>
        </div>

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead
            className="  uppercase "
            style={{
              color: "#ffbb00",
              fontWeight: "900",
              backgroundColor: "#dc3545",
            }}
          >
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Title
              </th>
              <th scope="col" className="px-6 py-3">
                Category Name
              </th>

              <th scope="col" className="px-6 py-3">
                Date & Time
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {blogs.length > 0 &&
              blogs.map((item, index) => (
                <tr className="bg-white border-b" key={item._id}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {skip + index + 1}
                  </th>
                  <td className="px-6 py-4 font-medium text-gray-900">
                    {item.name}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900">
                    {item.category != null ? item.category.name : "No Category"}
                  </td>

                  <td className="px-6 py-4 font-medium text-gray-900">
                    {moment(item.time).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="btn-yellow mx-2"
                      onClick={() => {
                        setDeleteModal(item._id);
                      }}
                    >
                      <IoTrashOutline className="text-lg" />
                    </Button>

                    <Button
                      variant="danger"
                      className="btn-yellow mx-2"
                      onClick={() => {
                        setEditModal(item._id);
                      }}
                    >
                      <FaBlogger className="text-lg" />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-3">
          <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
              Are you sure ?
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <button
              onClick={() => {
                onClickDelete();
              }}
              className="ml-4 btn  border border-red-600 font-medium text-red-600 hover:bg-red-600 hover:text-white focus:bg-red-600 focus:text-white active:bg-primary/90"
            >
              Yes
            </button>

            <button
              onClick={handleClose}
              className="ml-4 btn  border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90  "
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modalw90"
        show={editshow}
        onHide={edithandleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
             {blog.name}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{moment(blog.time).format("MMMM Do YYYY, h:mm:ss a")}</h5>
          <div className="flex justify-center">
          <img
                  className="rounded-t-lg my-5 "
                  src={`${backendUrl}/Blog/${blog.image}`}
                  alt=""
                />
          </div>
        
          <div dangerouslySetInnerHTML={{__html: blog.content}}></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Blogs;
