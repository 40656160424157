import React from "react";

const Developer = () => {
  return (
    <div>
      <div className=" mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">
          <div>
            <h5
              className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
              style={{ maxWidth: "750px" }}
            >
              Smoother Transition with Seamless Integration
            </h5>
            <h5
              className="text-base  ml-8 text-black mt-8 "
              style={{ maxWidth: "750px" }}
            >
              You can seamlessly integrate our platform SDKs, E-commerce
              plug-ins, server integrations, sample integrations, community
              integration, and partner channels, providing you with a wide range
              of options to choose from. By ensuring a hassle-free transition
              for your customers, you can also reap the rewards of their
              success.
            </h5>
          </div>
          <img
            className="place-self-end"
            alt=""
            src="./public/developerist.png"
          />
        </div>
      </div>

      <div className="my-12">
        <h5 className="text-5xl prtextcolor text-center font-semibold">
          Platform SDKs
        </h5>
        <h5 className="text-xl text-black text-center">
          Payment Gateway Integration Documentation
        </h5>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full my-8">
          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1215.png"
                alt=""
              />
            </div>
            <div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Php</h5>
            </div>

            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>

          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1233.png"
                alt=""
              />
            </div>

            <div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Java</h5>
            </div>

            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>

          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1258.png"
                alt=""
              />
            </div>

            <div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Python</h5>
            </div>
            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>

          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1282.png"
                alt=""
              />
            </div><div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Asp.net</h5>
            </div>
            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full my-8">
          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1227.png"
                alt=""
              />
            </div>
            <div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Android</h5>
            </div>

            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>

          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1284.png"
                alt=""
              />
            </div>

            <div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Ruby On Rails</h5>
            </div>

            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>

          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1288.png"
                alt=""
              />
            </div>

            <div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">Wordpress</h5>
            </div>
            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>

          <div className="prservicebox text-center  ">
            <div className="flex justify-center">
              <img
                className="w-48 text-center"
                src="./public/v242_1286.png"
                alt=""
              />
            </div><div className="flex justify-center my-4">
            <h5 className="text-xl  font-semibold text-black">WooCommerce</h5>
            </div>
            <div className="flex justify-between mx-8">
                <h5 className="text-lg font-semibold prtextcolor">Docs</h5>
                <h5 className="text-lg font-semibold prtextcolor">Download</h5>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">
          <div>
            <h5
              className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
              style={{ maxWidth: "750px" }}
            >
              How To integrate our Payment Gateway?
            </h5>
            <h5
              className="text-base  ml-8 text-black mt-8 "
              style={{ maxWidth: "750px" }}
            >
              If you want to integrate Our payment gateway, you can follow these
              steps:
            </h5>
            <div className="mt-2 ml-8">
              <h5 className="prfactscolor text-base font-semibold flex">
                <img
                  className="mr-1"
                  src="./public/materialsymbolscounter1outline.svg"
                  alt=""
                />{" "}
                Register with Payripe to gain access to their services.
              </h5>
              <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                <img
                  className="mr-1"
                  src="./public/materialsymbolscounter2outline.svg"
                  alt=""
                />{" "}
                You can either obtain a payment gateway integration kit from
                Payripe or download the SDK
              </h5>
              <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                <img
                  className="mr-1"
                  src="./public/materialsymbolscounter3outline.svg"
                  alt=""
                />
                Input the key and other necessary details provided by Payripe
                into the kit or SDK.
              </h5>
            </div>
          </div>
          <img
            className="place-self-end"
            alt=""
            src="./public/developersec.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Developer;
