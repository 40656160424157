import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
const Ecommerce = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ecommerce</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Create A Seamless Shopping Experience For Your Customers
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Ecommerce Web Design Services Help You Create A Seamless
                Shopping Experience For Your Customers.
              </h5>
              <a href="https://pg.payripe.in/register" target="_blank">
              <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
              </button>
              </a>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/ecommercewebpageamico.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                High-Converting Online Store For You
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Ecommerce Web Design Services Are Tailored To Help You Build A High-Converting Online Store For Your Business.We Focus On Creating A Seamless Shopping Experience For Your Customers.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
                 User-Friendly Navigation
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                 Mobile-Optimized Design
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                 Fast And Secure Checkout
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                  Product Showcase
                </h5>
              </div>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/ecommercecampaignbro.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className=""
              alt=""
              src="./public/ecommercecheckoutlaptoprafiki.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Enhance Your Users Shopping Journey
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our User Experience Optimization Services Are Designed To Enhance Your Customers' Shopping Journey And Improve Their Overall Experience On Your Website.Streamline The Purchase Process, And Ultimately Increase Conversions.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
                 User-Friendly Navigation
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                 User Journey Mapping
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                 Seamless Checkout
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                  Continuous Optimization
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
