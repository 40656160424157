import React from "react";

export const LoginRequest = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/login`,
    {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    }
  );

  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getAllCategories = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/list_category`,
    {
      method: "GET",
      headers: { "content-Type": "application/json" },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getAllBlogs = async (skip, limit) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/list_blog`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getLatestBlogs = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/list_latestblog`,
    {
      method: "GET",
      headers: { "content-Type": "application/json" },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const fetchBlog = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/fetch_blog`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const GetBrandInfo = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/list_brandinfo`,
    {
      method: "GET",
      headers: { "content-Type": "application/json" },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getAllBlogsByCategory = async (skip, limit, id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/list_blog_by_category`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        id: id,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addNewsLetter = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_FRONTEND_URL}/add_newsletter_email`,
    {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: data.email
      }),
    }
  );

  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const sendContactUsRequest = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_FRONTEND_URL}/add_contactus`,
    {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        message: data.message
      }),
    }
  );

  const returnResponse = await formResponse.json();
  return returnResponse;
};
