import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  getImages,
  deleteImage,
  addImage,
} from "../Apis/AuthenticatedDataService";
import PaginatedItems from "../pagination/pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoAddCircleSharp, IoTrashOutline } from "react-icons/io5";
import { FaEdit,FaClipboard } from "react-icons/fa";
import moment from "moment";

const Images = () => {
  const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const { id } = useParams();
  const [eventImages, setEventImages] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(6);
  const [count, setCount] = useState("");
  const [search, setSearch] = useState("");
  const [catId, setCatId] = useState("");
  const [files, setFiles] = useState("");
  const [editDate, setEditDate] = useState("");

  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const edithandleClose = () => setEditShow(false);
  const edithandleShow = () => setEditShow(true);
  const createhandleClose = () => setCreateShow(false);
  const createhandleShow = () => setCreateShow(true);

  const getData = async (skip, limit,search) => {
    const getEventImages = await getImages(skip, limit, search);
    setEventImages(getEventImages.data);
    setCount(getEventImages.count);
  };

  const fetchdata = (start, end) => {
    setSkip(end);
    setLimit(start);
    getData(end, start,search);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    getData(0, limit, e.target.value);
  };

  const setDeleteModal = (id) => {
    setCatId(id);
    handleShow();
  };

  const setEditModal = (item) => {
    setCatId(item._id);
    setEditDate(item.date);
    edithandleShow();
  };

  const copytoClipboard = async (item) => {
   console.log(item);
   const text = `${process.env.REACT_APP_BACKEND_URL}/blogImages/${item}` ;
   await navigator.clipboard.writeText(text);
   ToastSuccessNotifications('Image link has been copied to clipboard')
  };

  const onClickEdit = async (values) => {
    // const EditCategory = await editEvent(catId, values);
    // ToastSuccessNotifications(EditCategory.message);
    edithandleClose();
    getData(skip, limit,search);
  };

  const onClickDelete = async () => {
    const deleteCategory = await deleteImage(catId);
    ToastSuccessNotifications(deleteCategory.message);
    handleClose();
    getData(skip, limit,search);
  };

  const onClickCreate = async (values) => {
    let formData = new FormData();

    // Update the formData object
    formData.append("name", values.name);
    let AddEventImages;
    // const AddEvent = await addEventImage(formData);

    formData.append("image", files[0]);
    AddEventImages = await addImage(formData);

    ToastSuccessNotifications(AddEventImages.message);
    createhandleClose();
    getData(skip, limit,search);
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("File is required")
      .test("fileSize", "File size must be less than 1 MB", (value) => {
        if (!value) return true; // not required, so no validation necessary
        return value.size <= 1024 * 1024; // 1 MB in bytes
      }),
  });

  useEffect(() => {
    getData(skip, limit, search);
  }, []);
  return (
    <div>
      <div className="relative overflow-x-auto mx-8">
      <div className="flex justify-center">
          <div
            className="text-yellow bg-bloodred w-full rounded-lg text-center"
            style={{ fontSize: "30px", fontWeight: "700" }}
          >
            Images
          </div>
        </div>
        <div className="flex justify-between my-3">
        <input
            type="text"
            onChange={(e) => {
              let timer;
              clearTimeout(timer);

              // set a new timeout to execute the function after 500ms
              timer = setTimeout(() => {
                onChangeSearch(e);
              }, 1000);
            }}
            className="bg-gray-50 border w-80 border-gray-300 my-3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 "
            placeholder="search"
          />
          <Button
            variant="danger"
            onClick={() => {
              createhandleShow();
            }}
            className="btn-yellow h-10 mt-3"
          >
            Add <IoAddCircleSharp className="ml-2" />
          </Button>
        </div>

        <div className="grid grid-cols-3 gap-4 my-3">
          {eventImages.length > 0 &&
            eventImages.map((item, index) => (
              <div
                className="max-w-sm bg-white border border-gray-200 rounded-lg shadow "
                key={item._id}
              >
                <img
                  className="rounded-t-lg"
                  src={`${backendUrl}/blogImages/${item.image}`}
                  style={{ width: "800%", height: "400px" }}
                  alt=""
                />
                <h5 className="text-center text-red-400 font-semibold">
                  {item.name}
                </h5>
                <div className="p-5">
                  <div className="flex justify-center">
                    <Button
                      variant="danger"
                      className="btn-yellow mx-2"
                      onClick={() => {
                        setDeleteModal(item._id);
                      }}
                    >
                      <IoTrashOutline className="text-lg" />
                    </Button>

                    <Button
                      variant="danger"
                      className="btn-yellow mx-2"
                      onClick={() => {
                        copytoClipboard(item.image);
                      }}
                    >
                      <FaClipboard className="text-lg" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="flex justify-center mt-3">
          <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
              Are you sure ?
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <button
              onClick={() => {
                onClickDelete();
              }}
              className="ml-4 btn  border border-red-600 font-medium text-red-600 hover:bg-red-600 hover:text-white focus:bg-red-600 focus:text-white active:bg-primary/90"
            >
              Yes
            </button>

            <button
              onClick={handleClose}
              className="ml-4 btn  border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90  "
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Create modal */}
      <Modal show={createshow} onHide={createhandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
              Add Event Image
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Formik
              initialValues={{ name: "", file: "" }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required"),
                file: Yup.mixed().test(
                  "fileSize",
                  "File size too large",
                  (value) => !value || value.size <= 1024 * 1024 // 1 MB
                ),
              })}
              onSubmit={(values) => {
                onClickCreate(values);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="mb-4 ">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder=""
                    />
                    <div className="mb-2">
                      {errors.name && touched.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 ">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Image
                    </label>
                    <input
                      type="file"
                      multiple
                      id="file"
                      onChange={(event) => {
                        setFiles(event.target.files);
                      }}
                      className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder=""
                    />
                    <div className="mb-2">
                      {errors.file && touched.file && (
                        <span className="text-danger">{errors.file}</span>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-center text-center">
                    <button
                      type="submit"
                      className="btn border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Images;
