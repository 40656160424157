import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllCategories } from "../../Apis/DataService";

const Header = (prop) => {
  const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const [showMenu, setShowMenu] = useState(false);

  const [Categories, setCategories] = useState("");


  const getData = async () => {
    const fetchCategories = await getAllCategories();
    setCategories(fetchCategories.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="app-header  bg-red-600  shadow ">
      <div className="w-full mx-auto container md:p-6 p-4 md:flex md:items-center md:justify-between">
        <span className=" text-white text-2xl font-semibold sm:text-center ">
          <Link to="/">{prop.brand.domain == null ? 'News Website' : <img src={`${backendUrl}/Brandlogo/${prop.brand.domain.logo}`}  style={{width:'100px',height:'50px'}} alt="Logo" /> } </Link>
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500  sm:mt-0">
          <button
            className="inline-flex p-3 rounded lg:hidden text-gray-500 ml-auto hover:text-gray-900 outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              {showMenu ? (
                <path d="M6 18L18 6M6 6l12 12"></path>
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              )}
            </svg>
          </button>
          {/* <nav
            className={`${
              showMenu ? "flex" : "hidden"
            } lg:flex flex-wrap items-center text-base justify-center`}
          >
            <div className="dropdown">
              <button className="mr-5 text-white text-lg font-semibold hover:text-yellow-400">
                Categories
              </button>
              <div className="dropdown-content">
                {Categories.length > 0 &&
                  Categories.map((item) => (
                    <Link to={`/category/${item._id}`} key={item._id}>
                      {item.name}
                    </Link>
                  ))}
              </div>
            </div>
           
          </nav> */}

          <ul>
           {Categories.length > 0 &&
                  Categories.map((item) => (
                   
                    <Link  className="mr-5 text-white text-lg font-semibold hover:text-yellow-400" to={`/category/${item._id}`} key={item._id}>
                      {item.name}
                    </Link>
                    
                  ))}
                  
           </ul>
        </ul>
      </div>
    </div>
  );
};

export default Header;
