import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
const Payment = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Payment Software</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
              Secure Payment Software Solutions For Your Business Needs
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Payment Software Development Services Provide Secure And Reliable Payment Solutions For Your Business Needs.
              </h5>
              <a href="https://pg.payripe.in/register" target="_blank">
              <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
              </button>
              </a>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/ewalletpana.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
               Secure Payment Processing Solutions
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Secure Payment Processing Solutions Ensure The Safety And Privacy Of Your Business And Customers. With Our Expertise In Payment Processing, We Help You Minimize Risks And Prevent Fraudulent Activities.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
              PCI DSS Compliance
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
              Fraud Protection
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                Multiple Payment Options
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
               Secure Data Storage
                </h5>
              </div>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/onlinegroceriesrafiki.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className=""
              alt=""
              src="./public/mobilepaymentsrafiki.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
             Custom Payment Software Solutions
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
               Our Custom Payment Software Solutions Provide Secure And Streamlined Payment Processing For Your Business. We Develop Custom Payment Software That Is Tailored To Your Business Needs Nd Integrates Seamlessly With Your Existing Systems.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
             Secure Payment Processing
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                Customizable Payment Forms
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
              Seamless Integration
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
               Mobile Compatibility
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
