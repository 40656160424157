import React, { useState ,useEffect} from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastErrorNotifications } from "./components/Notifications/ToastNotifications";

const PrivateComponent = ({ children }) => {
  let navigate = useNavigate();
  const [jwt, setJwt] = useState(localStorage.getItem("user"));
 
  const [authUser, setauthUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

 

  

  
  

  return jwt ? <Outlet/> : <Navigate to="/login" />;
};

export default PrivateComponent;
