import React from "react";

const Newlayouttest = () => {
  return (
    <div>
      <body class="flex flex-col h-screen">
        <header class="bg-gray-800 text-white py-4">fdsaf</header>

        <main class="flex-1">fdsa</main>

        <footer class="bg-gray-800 text-white py-4">dfsa</footer>
      </body>
    </div>
  );
};

export default Newlayouttest;
