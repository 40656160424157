import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Login from "./components/Pages/Login";
import Logout from "./components/Pages/Logout";
import { Provider } from "react-redux";
import store from "./store/store";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ToastContainer, toast } from "react-toastify";
import Home from "./components/User/Home";
import AdminSidebar from "./components/sidebar/AdminSidebar";
import Layout from "./components/sidebar/Layout";
import Dashboard from "./components/Admin/Dashboard";
import NewsCategories from "./components/Admin/NewsCategories";
import Blogs from "./components/Admin/Blogs";
import AddBlog from "./components/Admin/AddBlog";
import Brand from "./components/Admin/Brand";
import Images from "./components/Admin/Images";

import PrivateComponent from "./AuthCheck";
import HomeLayout from "./components/User/Layout/Layout";
import Blog from "./components/User/Blog";
import VideosByCategory from "./components/User/VideosByCategory";
import VideosByState from "./components/User/VideosByState";
import Newlayouttest from "./components/User/Newlayouttest";
import BrandInfo from "./components/User/BrandInfo";

import ScrollToTop from "./components/components/ScrollToTop";

import Prlayout from "./components/Payripe/Layout/Prlayout";
import Prhome from "./components/Payripe/PrHome";

import Payin from "./components/Payripe/Products/Payin";
import Payout from "./components/Payripe/Products/Payout";
import Invoice from "./components/Payripe/Products/Invoice";
import Reseller from "./components/Payripe/Products/Reseller";
import WhiteLabel from "./components/Payripe/Products/WhiteLabel";
import Atm from "./components/Payripe/Products/Atm";
import Acquirer from "./components/Payripe/Products/Acquirer";

import AboutUs from "./components/Payripe/Resources/AboutUs";
import ContactUs from "./components/Payripe/Resources/ContactUs";
import PrivacyPolicy from "./components/Payripe/Resources/PrivacyPolicy";
import TermsAndConditions from "./components/Payripe/Resources/TermsAndConditions";
import Developer from "./components/Payripe/Resources/Developer";

import AutomationTesting from "./components/Payripe/Services/AutomationTesting";
import Bulksms from "./components/Payripe/Services/Bulksms";
import Content from "./components/Payripe/Services/Content";
import Digitalmarketing from "./components/Payripe/Services/Digitalmarketing";
import Ecommerce from "./components/Payripe/Services/Ecommerce";
import Graphicdesign from "./components/Payripe/Services/Graphicdesign";
import Mobileapp from "./components/Payripe/Services/Mobileapp";
import Payment from "./components/Payripe/Services/Payment";
import Software from "./components/Payripe/Services/Software";
import Website from "./components/Payripe/Services/Website";

import PrFooter from "./components/Payripe/Layout/PrFooter";

import React from "react";

function App() {
  return (
    <div>
      <ToastContainer />

      <ProSidebarProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Provider store={store}>
            <Routes>
              {/* Admin routes */}
              <Route path="/Login" element={<Login />} />
              <Route element={<PrivateComponent children={undefined} />}>
                <Route element={<Layout />}>
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/categories" element={<NewsCategories />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/addblog" element={<AddBlog />} />
                  <Route path="/images" element={<Images />} />
                  <Route path="/brand" element={<Brand />} />
                </Route>
              </Route>
              <Route element={<BrandInfo />}>
                <Route element={<HomeLayout />}>
                  <Route path="/blogshome" element={<Home />} />
                  <Route path="/blog/:id" element={<Blog />} />
                  <Route path="/category/:id" element={<VideosByCategory />} />
                  <Route path="/state/:id" element={<VideosByState />} />
                </Route>
              </Route>

              <Route element={<Prlayout />}> 
              <Route path="/" element={<Prhome />} />
              {/* products */}
              <Route path="/payin" element={<Payin />} />
              <Route path="/payout" element={<Payout />} />
              <Route path="/payout" element={<Payout />} />
              <Route path="/invoice" element={<Invoice />} />
              <Route path="/reseller" element={<Reseller />} />
              <Route path="/whitelabel" element={<WhiteLabel />} />
              <Route path="/atm" element={<Atm />} />
              <Route path="/acquirer" element={<Acquirer />} />
              {/* resources */}
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/developer" element={<Developer />} />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditions />}
              />
              {/* services */}
              <Route
                path="/automationtesting"
                element={<AutomationTesting />}
              />
              <Route path="/bulksms" element={<Bulksms />} />
              <Route path="/contentwriting" element={<Content />} />
              <Route path="/digitalmarketing" element={<Digitalmarketing />} />
              <Route path="/ecommerce" element={<Ecommerce />} />
              <Route path="/graphicdesign" element={<Graphicdesign />} />
              <Route path="/mobileapp" element={<Mobileapp />} />
              <Route path="/paymentsoftware" element={<Payment />} />
              <Route path="/softwaredevelopment" element={<Software />} />
              <Route path="/websitedesign" element={<Website />} />
             
              <Route path="/footer" element={<PrFooter />} />
              <Route path="/test" element={<Newlayouttest />} />
              </Route>
            </Routes>
          </Provider>
        </BrowserRouter>
      </ProSidebarProvider>
    </div>
  );
}

export default App;
