import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const PrHeader = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/main.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      {/* <nav className="frame-nav" id="navbar">
        <img
          className="removebg-preview-1-icon21"
          alt=""
          src="./public/111removebgpreview-1@2x.png"
        />
        <div className="products21">PRODUCTS</div>
        <div className="services21">SERVICES</div>
        <div className="resources42">RESOURCES</div>
        <div className="sign-in21">SIGN IN</div>
        <div className="sign-up21">SIGN UP</div>
        <div className="frame-child2"></div>
      </nav> */}

      <header>
        <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 ">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <Link to="/">
              <img
                src="./public/111removebgpreview-1@2x.png"
                className="mr-3 h-28 w-72"
                alt="Flowbite Logo"
              />
            </Link>
            <div className="flex items-center lg:order-2">
              <a
                href="https://pg.payripe.in/login"
                target="_blank"
                className="block py-2 mx-8 pr-4 pl-3 text-black text-lg border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 "
              >
                Sign in
              </a>
              <a
                href="https://pg.payripe.in/register"
                target="_blank"
                className="border-2 border-green-600  rounded-md text-green-700 text-lg px-4 py-2 "
              >
                Signup
              </a>
              <button
            className="inline-flex p-3 rounded lg:hidden text-gray-500 ml-auto hover:text-gray-900 outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              {showMenu ? (
                <path d="M6 18L18 6M6 6l12 12"></path>
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              )}
            </svg>
          </button>
            </div>
            <nav
            className={`${
              showMenu ? "flex" : "hidden"
            } lg:flex flex-wrap items-center text-base justify-center`}
          >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li></li>
                <div className="dropdown">
                  <button className="mx-2 text-black text-xl font-bold hover:text-green-500">
                    Products
                  </button>
                  <div className="dropdown-content">
                    <Link to="/payin"> Payin</Link>
                    <Link to="/payout">Payout</Link>
                    <Link to="/invoice"> Invoice Payment </Link>
                    <Link to="/atm"> Atm </Link>{" "}
                    <Link to="/whitelabel"> White Label Platform </Link>
                    <Link to="/reseller"> Reseller Panel </Link>
                    <Link to="/acquirer"> Acquirer Management</Link>
                  </div>
                </div>

                <div className="dropdown">
                  <button className="mx-2 text-black text-xl font-bold hover:text-green-500">
                    Services
                  </button>
                  <div className="dropdown-content">
                    <Link to="/websitedesign"> Website Design </Link>
                    <Link to="/ecommerce"> E-Commerce Website</Link>
                    <Link to="/softwaredevelopment">
                      {" "}
                      Software Development{" "}
                    </Link>
                    <Link to="/paymentsoftware"> Payment Software </Link>
                    <Link to="/mobileapp"> Mobile App Development </Link>
                    <Link to="/digitalmarketing"> Digital Marketing </Link>
                    <Link to="/contentwriting"> Content Writing </Link>
                    <Link to="/graphicdesign">Graphic Design </Link>
                    <Link to="/bulksms"> Bulk Sms/Voice/WhatsApp </Link>
                    <Link to="/automationtesting"> Automation Testing</Link>
                  </div>
                </div>

                <div className="dropdown">
                  <button className="mx-2 text-black text-xl font-bold hover:text-green-500">
                    Resources
                  </button>
                  <div className="dropdown-content">
                    <Link to="/aboutus"> About Us</Link>
                    <Link to="/contactus"> Contact Us</Link>
                    <Link to="/privacypolicy"> Privacy Policy </Link>
                    <Link to="/Termsandconditions"> Terms of Use</Link>
                    <Link to="/developer"> Developer</Link>
                    <Link to="/blogshome"> Blog</Link>
                  </div>
                </div>
              </ul>
            </nav>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default PrHeader;
