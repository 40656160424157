import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aboutus</title>
      </Helmet>
      <div className="prservicebox">

      <h5 className="text-5xl prtextcolor text-center font-semibold">
      ABOUT US
        </h5>
        <h5 className="text-xl text-black text-center font-semibold">
        India’s Most Favorite Payment Gateway
        </h5>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full px-8 py-8">
          <div className="">
            <h5
              className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
              style={{ maxWidth: "750px" }}
            >
              Our Vision
            </h5>
            <h5
              className="text-xl font-bold ml-8 text-black mt-8 "
              style={{ maxWidth: "750px" }}
            >
              Our vision for our payment gateway website is to provide a secure,
              efficient, and user-friendly platform for individuals and
              businesses to make and receive payments online. We aim to simplify
              the payment process and make it accessible to everyone, regardless
              of their location or currency.
            </h5>
          </div>
          <img
            className="place-self-end"
            alt=""
            src="./public/unsplashnpxxwgq33zq@2x.png"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 w-full px-8 py-8">
        <img
            className="place-self-end"
            alt=""
            src="./public/unsplash505eectw54k@2x.png"
          />
          <div className="">
            <h5
              className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
              style={{ maxWidth: "750px" }}
            >
              Our Approach
            </h5>
            <h5
              className="text-xl font-bold ml-8 text-black mt-8 "
              style={{ maxWidth: "750px" }}
            >
              Our approach to developing a payment gateway website involves prioritizing security, ease of use, and flexibility. We will employ state-of-the-art security measures to protect sensitive financial information, while also ensuring that our website is easy to navigate and use.
            </h5>
          </div>
          
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 w-full px-8 py-8">
          <div className="">
            <h5
              className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
              style={{ maxWidth: "750px" }}
            >
            Our Process
            </h5>
            <h5
              className="text-xl font-bold ml-8 text-black mt-8 "
              style={{ maxWidth: "750px" }}
            >
              Our process for developing a payment gateway website involves several stages, including research, design, development, and testing. We will conduct extensive research to understand the needs and preferences of our target audience and the industry trends. We will then create a design that is intuitive, aesthetically pleasing, and optimized for user experience.
            </h5>
          </div>
          <img
            className="place-self-end"
            alt=""
            src="./public/unsplashnpxxwgq33zq@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
