import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchBlog } from "../Apis/DataService";
import moment from "moment";
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import { AiFillPlayCircle } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastSuccessNotifications } from "../Notifications/ToastNotifications";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaginatedItems from "../pagination/pagination";

const Blog = () => {
  const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const { id } = useParams();
  const [blog, setBlog] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState('');
  const [comments, setComments] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = async () => {
    const getBlog = await fetchBlog(id);
    setBlog(getBlog.data);
    setCategory(getBlog.data.category.name)
  };

  const fetchdata = (start, end) => {
    setSkip(end);
    setLimit(start);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className=" " style={{ margin: "60px 100px 100px 100px" }}>
     <h5 className="text-lg font-bold text-red-400">{category != '' && category}</h5>

     <h5 className="text-5xl font-bold text-black my-2">{blog.name}</h5>

     <h5 className="text-sm font-bold text-black mt-4 mb-3">{moment(blog.date).format("MMMM Do YYYY, h:mm:ss a")}</h5>


     <img className="object-fill h-full w-full"  src={`${backendUrl}/blog/${blog.image}`}></img>

     <div className="my-8" dangerouslySetInnerHTML={{__html: blog.content}}></div>
    </div>
  );
};

export default Blog;
