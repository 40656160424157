import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
const Invoice = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Invoice</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto ">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Secure And Convenient Invoice Payments With Our Payment Gateway
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                {" "}
                Effortlessly Pay Your Invoices Online With Our Secure And
                User-Friendly Payment Gateway Solution.
              </h5>
              <a href="https://pg.payripe.in/register" target="_blank">
              <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
              </button>
              </a>
            </div>
            <img className="place-self-end" alt="" src="./public/invoicerafiki.svg" />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Simple & Secure Invoice Payments
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                {" "}
                Experience Peace Of Mind With Our Secure Payment System,
                Ensuring That Your Invoice Payments Are Always Safe And Reliable
                With Our Simple And Secure Invoice Payment Solution
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img className="mr-1" src="./public/materialsymbolscounter1outline.svg" alt="" /> Streamlined Payment Process
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                <img className="mr-1" src="./public/materialsymbolscounter2outline.svg" alt="" /> Multiple Payment Methods
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                <img className="mr-1" src="./public/materialsymbolscounter3outline.svg" alt="" /> Automatic Recurring Payments
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                <img  className="mr-1" src="./public/materialsymbolscounter4outline.svg" alt="" /> Secure Data Encryption
                </h5>
              </div>
              
            </div>
            <img className="place-self-end" alt="" src="./public/invoicebro.svg" />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img className="" alt="" src="./public/receiptcuate.svg" />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Manage Your Invoices with Payripe
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                {" "}
                Payripe Is Your Go-To Solution For Easy Invoice Management. Our
                Platform Provides A Seamless Experience To Help You Efficiently
                Manage, Organize And Pay Your Invoices Online. Sign Up Today!
              </h5>
              

              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img className="mr-1" src="./public/materialsymbolscounter1outline.svg" alt="" />Automatic Invoice Reminders
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                <img className="mr-1" src="./public/materialsymbolscounter2outline.svg" alt="" /> Secure Payment Processing
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                <img className="mr-1" src="./public/materialsymbolscounter3outline.svg" alt="" /> User-Friendly Platform
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                <img  className="mr-1" src="./public/materialsymbolscounter4outline.svg" alt="" /> Customizable Payment Options
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
