import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
const PrFooter = () => {
  return (
    <div className="footerbox py-3">
      <Helmet>
        <meta charSet="utf-8" />

        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 ">
        <div className="mx-12">
          <img
            className="w-64"
            src="./public/111removebgpreview-2@2x.png"
            alt=""
          />
          <h5 className="text-white font-semibold text-base  text-center md:ml-8">
            The product suite of Payripe provides a complete payment solution
            for businesses in India to accept, process, and distribute payments.
            It offers various payment modes, including credit card, debit card,
            net banking, UPI, and popular wallets such as JioMoney, Mobikwik,
            Airtel Money, FreeCharge, Ola Money, and PayZapp. 
          </h5>
          <h5 className="text-white font-semibold text-base  text-center md:ml-8 mt-5">In addition to
            facilitating payments, Payripe enhances the banking experience for
            businesses by streamlining financial processes. It provides access
            to fully-functional current accounts, enables efficient payouts.</h5>
        </div>

        <div className="mx-12">
          <h5 className="text-white font-bold text-3xl">Accept Payments</h5>
          <div>
            <ul>
              <li className="text-white text-lg font-semibold">
                Payment Gateway
              </li>
              <li className="text-white text-lg font-semibold">
                Payment Pages
              </li>
              <li className="text-white text-lg font-semibold">
                Payment Links
              </li>
              <li className="text-white text-lg font-semibold"> QR Codes</li>
              <li className="text-white text-lg font-semibold">
                {" "}
                Smart Collect
              </li>
            </ul>

            <h5 className="text-white font-bold text-3xl mt-5">Our Products</h5>
            <div>
              <ul>
                <li className="text-white text-lg font-semibold">
                  {" "}
                  <Link to="/payin"> Payin</Link>
                </li>
                <li className="text-white text-lg font-semibold">
                  {" "}
                  <Link to="/payout">Payout</Link>
                </li>
                <li className="text-white text-lg font-semibold">
                  <Link to="/invoice"> Invoice Payment </Link>
                </li>
                <li className="text-white text-lg font-semibold">
                  <Link to="/atm"> Atm </Link>{" "}
                </li>
                <li className="text-white text-lg font-semibold">
                  {" "}
                  <Link to="/whitelabel"> White Label Platform </Link>
                </li>
                <li className="text-white text-lg font-semibold">
                  {" "}
                  <Link to="/reseller"> Reseller Panel </Link>
                </li>
                <li className="text-white text-lg font-semibold">
                  {" "}
                  <Link to="/acquirer"> Acquirer Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mx-12">
          <h5 className="text-white font-bold text-3xl">Our Services</h5>
          <div>
            <ul>
              <li className="text-white text-lg font-semibold">
                <Link to="/websitedesign"> Website Design </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/ecommerce"> E-Commerce Website</Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/softwaredevelopment"> Software Development </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                {" "}
                <Link to="/paymentsoftware"> Payment Software </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                {" "}
                <Link to="/mobileapp"> Mobile App Development </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/digitalmarketing"> Digital Marketing </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/contentwriting"> Content Writing </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/graphicdesign">Graphic Design </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/bulksms"> Bulk Sms/Voice/WhatsApp </Link>
              </li>
              <li className="text-white text-lg font-semibold">
                <Link to="/automationtesting"> Automation Testing</Link>
              </li>
            </ul>
          </div>
          <h5 className="text-white font-bold text-3xl mt-5">Find Us Online</h5>
          <div className="flex ">
            <div className="text-4xl mx-2">
              <AiFillFacebook />
            </div>
            <div className="text-4xl mx-2">
              <AiOutlineInstagram />
            </div>
            <div className="text-4xl mx-2">
              <AiFillYoutube />
            </div>
            <div className="text-4xl mx-2">
              <AiFillTwitterSquare />
            </div>
          </div>
        </div>

        <div className="mx-12">
          <h5 className="text-white font-bold text-3xl">Resources</h5>
          <div>
            <ul>
              <li className="text-white text-lg font-semibold">  <Link to="/aboutus"> About Us</Link> </li>
              <li className="text-white text-lg font-semibold">
              <Link to="/privacypolicy">  Privacy Policy </Link> 
              </li>
              <li className="text-white text-lg font-semibold">
              <Link to="/Termsandconditions"> Terms of Use</Link> 
              </li>
              <li className="text-white text-lg font-semibold">   <Link to="/developer"> Developer</Link></li>
              <li className="text-white text-lg font-semibold"> <Link to="/blogshome"> Blog</Link> </li>
            </ul>
          </div>

          <h5 className="text-white font-bold text-3xl mt-3">Contact Us</h5>
          <div>
            <h5 className="text-white text-lg font-semibold ">
              +91 8813974517 info@payripe.in J 802, BPTP Discovery Park,
              Faridabad, Pin- 121003
            </h5>
          </div>
        </div>
      </div>
      <hr className="mt-3"/>
      <div className="'text-white text-2xl font-bold text-center py-5">
        © 2023 Payripe | All Rights Reserved.
      </div>
    </div>
  );
};

export default PrFooter;
