import axios from "axios";

export const getStates = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/list_states`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getNewsCategory = async (skip, limit, search) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/list_category`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addNewsCategory = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/add_category`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        categoryname: data.name,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const editNewsCategory = async (categoryid, data, token) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/edit_category`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        categoryname: data.name,
        categoryid: categoryid,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const deleteNewsCategory = async (categoryid) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/delete_category`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        categoryid: categoryid,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addBlog = async (data) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      authorization: JSON.parse(localStorage.getItem("user")).token,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/add_blog`,
      data,
      {
        headers: headers,
      }
    );
    const json = await response.data;

    return json;
  } catch (error) {
    throw error;
  }
};

export const getBlogs = async (skip, limit, search) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/list_blog`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const deleteBlog = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/delete_blog`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const findBlog = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/fetch_blog`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};


//brand


export const addBrand = async (data, options) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      authorization: JSON.parse(localStorage.getItem("user")).token,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/add_brandinfo`,
      data,
      {
        headers: headers,
      }
    );
    console.log(response.data);
    const json = await response.data;

    return json;
  } catch (error) {
    throw error;
  }
};

export const editBrand = async (data, options) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      authorization: JSON.parse(localStorage.getItem("user")).token,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/edit_brandinfo`,
      data,
      {
        headers: headers,
      }
    );
    console.log(response.data);
    const json = await response.data;

    return json;
  } catch (error) {
    throw error;
  }
};

export const listBrand = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/list_brandinfo`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

//images
export const addImage = async (data) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      authorization: JSON.parse(localStorage.getItem("user")).token,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/add_image`,
      data,
      {
        headers: headers,
      }
    );
    const json = await response.data;

    return json;
  } catch (error) {
    throw error;
  }
};

export const deleteImage = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/delete_image`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getImages = async (skip, limit, search) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/admin/list_images`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};
