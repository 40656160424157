import React, { useState, useEffect } from "react";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";


const SocialMedia = () => {
  const [brandinfo, setbrandinfo] = useState("");
  const DomainInfo = useSelector((state) => state.domain);
  useEffect(() => {
    
    setbrandinfo(DomainInfo);
  }, []);
  return (
    <div>
      <h5 className="text-xl font-bold text-black text-center mt-4">
        Social Media
      </h5>
      <div className="flex justify-center  my-5">
        <div className="mx-3 text-center ">
        <a href={brandinfo.domain == null ? '#': `${brandinfo.domain.facebook}`} target="_blank">
          <div
            style={{ fontSize: "50px" }}
            className="text-center text-blue-800"
          >
           <AiFillFacebook />
          </div>
          </a> 
        </div>

        <div className="mx-3 text-center ">
        <a href={brandinfo.domain == null ? '#': `${brandinfo.domain.twitter}`} target="_blank">
          <div
            style={{ fontSize: "50px" }}
            className="text-center text-cyan-400"
          >
            <AiFillTwitterSquare />
          </div>
          </a>
        </div>
        
        <div className="mx-3 text-center ">
        <a href={brandinfo.domain == null ? '#': `${brandinfo.domain.youtube}`} target="_blank">
          <div
            style={{ fontSize: "50px" }}
            className="text-center text-red-600"
          >
            <AiFillYoutube />
          </div>
          </a>
        </div>

        <div className="mx-3 text-center ">
        <a href={brandinfo.domain == null ? '#': `${brandinfo.domain.instagram}`} target="_blank">
          <div
            style={{ fontSize: "50px" }}
            className="text-center text-purple-600"
          >
            <AiOutlineInstagram />
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
