import React, { useState, useEffect } from "react";
import {
  getNewsCategory,
  deleteNewsCategory,
  editNewsCategory,
  addNewsCategory,
} from "../Apis/AuthenticatedDataService";
import PaginatedItems from "../pagination/pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoAddCircleSharp ,IoTrashOutline} from "react-icons/io5";
import { FaEdit } from "react-icons/fa";

const NewsCategories = () => {
  const [categories, setCategories] = useState('');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState("");
  const [search, setSearch] = useState("");
  const [catId, setCatId] = useState("");
  const [editCatName, setEditCatName] = useState("");

  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [createshow, setCreateShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const edithandleClose = () => setEditShow(false);
  const edithandleShow = () => setEditShow(true);
  const createhandleClose = () => setCreateShow(false);
  const createhandleShow = () => setCreateShow(true);

  const [authUser, setauthUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const getData = async (skip, limit, search) => {
    const getCategories = await getNewsCategory(
      skip,
      limit,
      search
    );
    setCategories(getCategories.data);
    setCount(getCategories.count);
  };

  const fetchdata = (start, end) => {
    setSkip(end);
    setLimit(start);
    getData(end, start, search);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    getData(0, limit, e.target.value);
  };

  const setDeleteModal = (id) => {
    setCatId(id);
    handleShow();
  };

  const setEditModal = (item) => {
    setCatId(item._id);
    setEditCatName(item.name);
    edithandleShow();
  };

  const onClickEdit = async (values) => {
    const EditCategory = await editNewsCategory(catId, values);
    ToastSuccessNotifications(EditCategory.message);
    edithandleClose();
    getData(skip, limit, search);
  };

  const onClickDelete = async () => {
    const deleteCategory = await deleteNewsCategory(catId);
    ToastSuccessNotifications(deleteCategory.message);
    handleClose();
    getData(skip, limit, search);
  };

  

  const onClickCreate = async (values) => {
    const AddCategory = await addNewsCategory( values);
    ToastSuccessNotifications(AddCategory.message);
    createhandleClose();
    getData(skip, limit, search);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Category Name is required")
      .min(2, "Name is too short - should be at least 2 characters.")
      .max(50, "Name is too long - should be no more than 50 characters."),
  });

  useEffect(() => {
    getData(skip, limit, search);
  }, []);
  return (
    <div>
      <div className="relative overflow-x-auto mx-8">
      <div className="flex justify-center">
          <div
            className="text-yellow bg-bloodred w-full rounded-lg text-center"
            style={{ fontSize: "30px", fontWeight: "700" }}
          >
             Categories
          </div>
        </div>
        <div className="flex justify-between">
          <input
            type="text"
            onChange={(e) => {
              let timer;
              clearTimeout(timer);

              // set a new timeout to execute the function after 500ms
              timer = setTimeout(() => {
                onChangeSearch(e);
              }, 1000);
            }}
            className="bg-gray-50 border w-80 border-gray-300 my-3 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 "
            placeholder="search"
          />
          <Button
            variant="danger"
            onClick={() => {
              createhandleShow();
            }}
            className="btn-yellow h-10 mt-3"
          >
            Add <IoAddCircleSharp className="ml-2"/>
          </Button>
        </div>

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead
            className="  uppercase "
            style={{
              color: "#ffbb00",
              fontWeight: "900",
              backgroundColor: "#dc3545",
            }}
          >
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Category Name
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {categories.length > 0 &&
              categories.map((item, index) => (
                <tr className="bg-white border-b" key={item._id}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {skip + index + 1}
                  </th>
                  <td className="px-6 py-4 font-medium text-gray-900">
                    {item.name}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="btn-yellow mx-2"
                      onClick={() => {
                        setDeleteModal(item._id);
                      }}
                    >
                      <IoTrashOutline className="text-lg"/>
                    </Button>

                    <Button
                      variant="danger"
                      className="btn-yellow mx-2"
                      onClick={() => {
                        setEditModal(item);
                      }}
                    >
                      <FaEdit className="text-lg"/>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-3">
          <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
              Are you sure ?
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <button
              onClick={() => {
                onClickDelete();
              }}
              className="ml-4 btn  border border-red-600 font-medium text-red-600 hover:bg-red-600 hover:text-white focus:bg-red-600 focus:text-white active:bg-primary/90"
            >
              Yes
            </button>

            <button
              onClick={handleClose}
              className="ml-4 btn  border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90  "
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      <Modal show={editshow} onHide={edithandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
              Edit Category
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Formik
              initialValues={{ name: editCatName }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onClickEdit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Category Name
                    </label>
                    <Field
                      name="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                    />
                  </div>
                  <div className="mb-4">
                    {errors.name && touched.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>

                  <div className="flex justify-center text-center">
                    <button
                      type="submit"
                      className="btn border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      {/* Create modal */}
      <Modal show={createshow} onHide={createhandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-lg font-medium  text-slate-700 dark:text-navy-100">
              Add Category
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Formik
              initialValues={{ name: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onClickCreate(values)
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Category Name
                    </label>
                    <Field
                      name="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                    />
                  </div>
                  <div className="mb-4">
                    {errors.name && touched.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>

                  <div className="flex justify-center text-center">
                    <button
                      type="submit"
                      className="btn border border-green-600 font-medium text-green-600 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white active:bg-primary/90"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewsCategories;
