import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addNewsLetter } from "../Apis/DataService";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";

const Prhome = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, { setSubmitting ,resetForm }) => {
    const add = await addNewsLetter(values);
    if ((add.message = "Success")) {
      ToastSuccessNotifications(
        "You have Successfully Subscribed our NewsLetter"
      );
    } else {
      ToastErrorNotifications("Error,Try again");
    }

    setSubmitting(false);
    resetForm();
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Payripe </title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto px-2 md:px-0">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-7xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Customizable Payment Gateway Solution For Your Business
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              ></h5>
              <a href="https://pg.payripe.in/register" target="_blank">
                <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
                </button>
              </a>
            </div>
            <img
              className="place-self-end"
              style={{ width: "550px" }}
              alt=""
              src="./public/3789842-1@2x.png"
            />
          </div>
        </div>

        <div className="my-8">
          <h5 className="text-5xl prtextcolor text-center font-semibold">
            Our Products
          </h5>
          <h5 className="text-2xl text-black text-center font-semibold my-8">
            Welcome To Our Payment Gateway Website! We Offer A Range Of Products
            That Make It Easy And Secure For You To Process Payments Online.
            Here's A Brief Overview Of Our Product Offerings:
          </h5>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full my-8">
            <Link to="/payin">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-1@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">PAYIN</h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    This Feature Allows You To Easily Transfer Funds From Your
                    Bank Account Or Credit Card To Your Digital Wallet For
                    Hassle-Free Payments.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/payout">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-3@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">PAY OUT</h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    Effortlessly Transfer Funds To Your Bank Account With Our
                    Fast And Reliable Pay Out Feature. Easy Fast And Simple Way
                    Of Payment.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/invoice">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-6@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">
                  INVOICE PAYMENT
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    Streamline Your Payment Process With Our Invoice Payment
                    Feature - Easily Pay And Track Invoices Online. Best Invoice
                    Payment System.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/whitelabel">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-2@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">WHITE LABEL</h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    Our White Label Platform Allows You To Fully Customize And
                    Brand The Payment Gateway With Your Own Logo, Colors, And
                    Branding, Providing ...
                  </h5>
                </div>
              </div>
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full my-8 ">
            <Link to="/atm">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-5@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">ATM</h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    ThWe Provides ATM Withdrawal Feature. It Is Designed To
                    Provide Our Users With Convenient Access To Their Money, 24
                    Hours A Day, 7 Days A Week.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/acquirer">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-9@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">ACQUIRER</h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    Manage And Monitor Transactions, Set Customized Pricing, And
                    Provide Exceptional Support To Your Customers With Our
                    Reseller Panel Feature.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/reseller">
              <div className="prbox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/pay-in-8@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="text-white text-2xl font-bold">
                  RESELLER PANEL
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-white prboxfont  w-48">
                    Efficiently Manage And Track All Acquired Merchants And
                    Transactions In Real-Time With Our Intuitive Acquirer
                    Management Feature.
                  </h5>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* Our Services */}
        <div className="my-8">
          <h5 className="text-5xl prtextcolor text-center font-semibold">
            Our Services
          </h5>
          <h5 className="text-2xl text-black text-center font-semibold my-8">
            Our Payment Gateway Services Provide Secure And Reliable Payment
            Processing Solutions For Businesses Of All Sizes. With Our
            Easy-To-Use Platform, You Can Accept Payments From Customers
            Anywhere In The World, While Ensuring Their Sensitive Information Is
            Kept Safe.
          </h5>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 w-full my-8">
            <Link to="/websitedesign">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/web-development@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  WEBSITE DESIGN
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Transform Your Online Presence With Our Expert Website
                    Design Services - Stand Out, Connect With Your Audience, And
                    Leave A Lasting Impression!
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/ecommerce">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/shopping-cart@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">E-COMMERCE</h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Unlock The Potential Of Your Online Business With Our
                    ECommerce Website Design Services. Let Us Create A Stunning,
                    User-Friendly Website.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/softwaredevelopment">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/software@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">SOFTWARE DEV.</h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Our Team Of Expert Software Developers Can Help You Build
                    Custom Solutions That Streamline Your Operations And Take
                    Your Business To The Next Level.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/paymentsoftware">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/money@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  PAYMENT SOFTWARE
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Streamline Your Payment Processes And Enhance Your Customer
                    Experience With Our Top-Notch Payment Software Development
                    Services.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/mobileapp">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/app@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  MOBILE APP DEV.
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Develop A Flawless And User-Friendly Mobile App For Your
                    Business That Enhances Your Customer Experience With Our App
                    Development Solutions.
                  </h5>
                </div>
              </div>
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 w-full my-8">
            <Link to="/digitalmarketing">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/web-development@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  DIGITAL MARKETING
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Stay Ahead Of The Competition With Our Data-Driven Digital
                    Marketing Strategies And Boost Your Online Presence. Contact
                    Us Today!
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/contentwriting">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/shopping-cart@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  CONTENT WRITING
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Elevate Your Brand's Storytelling With Our Creative And
                    Compelling Content Writing Solutions. Let Your Words Speak
                    For Your Brand!
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/graphicdesign">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/software@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  GRAPHIC DESIGNING
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Bring Your Brand To Life. Stand Out In A Crowded Marketplace
                    With Our Visually Stunning And Impactful Graphic Design
                    Solutions.
                  </h5>
                </div>
              </div>
            </Link>

            <Link to="/bulksms">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/money@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  BULK SMS/WHATSAPP
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Experience Seamless Communication And Engagement With Your
                    Customers With Our Reliable And Cost-Effective Messaging
                    Solutions.
                  </h5>
                </div>
              </div>
            </Link>
            <Link to="/automationtesting">
              <div className="prservicebox text-center py-3 ">
                <div className="flex justify-center">
                  <img
                    className="w-20 text-center"
                    src="./public/app@2x.png"
                    alt=""
                  />
                </div>

                <h5 className="prtextcolor text-xl font-bold">
                  AUTOMATION TEST
                </h5>
                <div className="flex justify-center">
                  <h5 className="text-black prboxfont  w-48">
                    Ensure The Highest Level Of Quality And Performance Of Your
                    Software With Our Advanced Automation Testing Solutions.
                    Contact Now!
                  </h5>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* Our Features */}
        <div className="my-8">
          <h5 className="text-5xl prtextcolor text-center font-semibold">
            Features
          </h5>
          <h5 className="text-2xl text-black text-center font-semibold my-8">
            Take Control Of Your Finances Like A Pro With Just One Click! Say
            Goodbye To The Hassle Of Juggling Multiple Platforms, And Hello To
            The Convenience Of Managing All Your Money Matters In One Place.
            Streamline Your Financial Life Today With Our User-Friendly
            Platform.
          </h5>
          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 w-full my-8">
            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/user-friendly-1@2x.png"
                  alt=""
                />
              </div>

              <h5 className="text-black textlg font-bold my-3">
                User-Friendly Interface
              </h5>
              <div className="flex justify-center">
                <h5 className="text-black prboxfont  w-48">
                  Our Payment Gateway System Has A User-Friendly Interface With
                  Easy Navigation
                </h5>
              </div>
            </div>

            {/* box */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/transfer-2@2x.png"
                  alt=""
                />
              </div>

              <h5 className="text-black textlg font-bold my-3">
                Fast Transaction Processing
              </h5>
              <div className="flex justify-center">
                <h5 className="text-black prboxfont  w-48">
                  Our Payment Gateway Has Fast And Reliable Transaction
                  Processing
                </h5>
              </div>
            </div>

            {/* box */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/secure-2@2x.png"
                  alt=""
                />
              </div>

              <h5 className="text-black textlg font-bold my-3">
                Secure Payment Processing
              </h5>
              <div className="flex justify-center">
                <h5 className="text-black prboxfont  w-48">
                  Security Is Paramount For Us. Our Payment System Has Robust
                  Security Measures
                </h5>
              </div>
            </div>

            {/* box */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/user-friendly-3@2x.png"
                  alt=""
                />
              </div>

              <h5 className="text-black textlg font-bold my-3">
                Automated Billing
              </h5>
              <div className="flex justify-center">
                <h5 className="text-black prboxfont  w-48">
                  An Automated Billing System Is Integrated Into Our System
                </h5>
              </div>
            </div>

            {/* box */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/multi-platform-2@2x.png"
                  alt=""
                />
              </div>

              <h5 className="text-black textlg font-bold my-3">
                Multiple Platform Support
              </h5>
              <div className="flex justify-center">
                <h5 className="text-black prboxfont  w-48">
                  Our Payment Gateway Supports Multiple Platforms To Enable
                  Users
                </h5>
              </div>
            </div>

            {/* box */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/transfer-3@2x.png"
                  alt=""
                />
              </div>

              <h5 className="text-black textlg font-bold my-3">
                E-Commerce Platform Support
              </h5>
              <div className="flex justify-center">
                <h5 className="text-black prboxfont  w-48">
                  Our Payment Gateway Is Compatible With Popular E-Commerce
                  Platforms
                </h5>
              </div>
            </div>
          </div>
        </div>

        {/* serving 10000 */}
        <div className="my-16 ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-7xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Serving 10,000+ Companies Globally
              </h5>
              <hr className="prtextcolor" />
              <h5
                className="text-xl font-semibold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                MAJOR INDIAN BRANDS RELY ON THE PAYRIPE PAYMENT GATEWAY FOR
                HANDLING THEIR ONLINE PAYMENT COLLECTIONS, VENDOR PAYOUTS, AND
                FINANCIAL OPERATIONS. JOIN US TODAY AND ENJOY THE CONVENIENCE OF
                EFFICIENTLY MANAGING YOUR PAYMENTS AND FINANCIAL OPERATIONS.
              </h5>
            </div>
            <img
              className="place-self-end"
              style={{ width: "400px" }}
              alt=""
              src="./public/merchants-1@2x.png"
            />
          </div>
        </div>

        {/* why we are best */}
        <h5 className="text-5xl prtextcolor text-center font-semibold">
          Why We Are Best?
        </h5>
        <div className="prbox my-16 ">
          <div className="grid grid-cols-1 md:grid-cols-3 w-full">
            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center ">
                <img
                  className="w-20 text-center"
                  src="./public/api-1@2x.png"
                  alt=""
                />
              </div>
              <div className="flex justify-center ">
                <h5 className="text-white text-xl w-40  my-3">
                  Easy-To-Use APIs That Require Minimal Setup
                </h5>
              </div>
            </div>

            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/api-3@2x.png"
                  alt=""
                />
              </div>

              <div className="flex justify-center ">
                <h5 className="text-white text-xl w-40  my-3">
                  Simple And Easy-To-Use Dashboard
                </h5>
              </div>
            </div>

            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/api-5@2x.png"
                  alt=""
                />
              </div>

              <div className="flex justify-center ">
                <h5 className="text-white text-xl w-40  my-3">
                  Fast And Simple Onboarding Process
                </h5>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 w-full">
            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center ">
                <img
                  className="w-20 text-center"
                  src="./public/api-2@2x.png"
                  alt=""
                />
              </div>
              <div className="flex justify-center ">
                <h5 className="text-white text-xl w-40  my-3">
                  Improved Success Rates
                </h5>
              </div>
            </div>

            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/api-4@2x.png"
                  alt=""
                />
              </div>

              <div className="flex justify-center ">
                <h5 className="text-white text-xl w-40  my-3">
                  A Secure And Reliable Platform
                </h5>
              </div>
            </div>

            {/* box1 */}
            <div className=" text-center py-3 ">
              <div className="flex justify-center">
                <img
                  className="w-20 text-center"
                  src="./public/api-6@2x.png"
                  alt=""
                />
              </div>

              <div className="flex justify-center ">
                <h5 className="text-white text-xl w-40  my-3">
                  24x7 Assistance And Technical Support
                </h5>
              </div>
            </div>
          </div>
        </div>

        {/* why we are best */}
        <h5 className="text-5xl prtextcolor text-center font-semibold">
          100+ Payment Options
        </h5>
        <div className="grid grid-cols-1 md:grid-cols-7 w-full my-24">
          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/12-1@2x.png"
              alt=""
            />
          </div>

          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/7-1@2x.png"
              alt=""
            />
          </div>

          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/5-1@2x.png"
              alt=""
            />
          </div>

          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/2-1@2x.png"
              alt=""
            />
          </div>

          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/4-1@2x.png"
              alt=""
            />
          </div>

          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/9-1@2x.png"
              alt=""
            />
          </div>

          <div className="flex justify-center my-3">
            <img
              className="w-20 text-center"
              src="./public/8-1@2x.png"
              alt=""
            />
          </div>
        </div>

        {/* why we are best */}
        <h5 className="text-5xl prtextcolor text-center font-semibold">
          Frequently Asked Questions
        </h5>
        <div className="prservicebox my-8">
          <div className="mx-8">
            <Accordion open={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)}>
                <h5 className="text-black"> Is Payripe Safe And Secure ?</h5>
              </AccordionHeader>
              <AccordionBody>
                <h5 className="text-xl font-normal">
                  {" "}
                  Payripe is a payment gateway that allows businesses to accept
                  and make payments through various channels such as
                  credit/debit cards, bank transfers, and mobile wallets. It
                  processes the transactions securely and provides businesses
                  with tools to manage their payment processes.
                </h5>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader onClick={() => handleOpen(2)}>
                <h5 className="text-black"> Is Payripe Safe And Secure ?</h5>
              </AccordionHeader>
              <AccordionBody>
                <h5 className="text-xl font-normal">
                  {" "}
                  Yes, Payripe takes security seriously and uses
                  industry-standard encryption and authentication protocols to
                  ensure that all transactions are safe and secure. It is also
                  PCI DSS compliant, which means that it meets the highest
                  security standards for handling credit card information.
                </h5>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3}>
              <AccordionHeader onClick={() => handleOpen(3)}>
                <h5 className="text-black">
                  {" "}
                  What Types of Payments Can Be Made Through Payripe ?
                </h5>
              </AccordionHeader>
              <AccordionBody>
                <h5 className="text-xl font-normal">
                  {" "}
                  Payripe supports a variety of payment methods, including
                  credit/debit cards, bank transfers, mobile wallets, and more.
                  The specific payment methods available may vary depending on
                  the country and region.
                </h5>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 4}>
              <AccordionHeader onClick={() => handleOpen(4)}>
                <h5 className="text-black">
                  Are There Any Fees Associated With Using Payripe ?
                </h5>
              </AccordionHeader>
              <AccordionBody>
                <h5 className="text-xl font-normal">
                  {" "}
                  Yes, Payripe charges fees for its services, which may include
                  transaction fees, monthly fees, and other charges. The fees
                  may vary depending on the type of service and the region.
                </h5>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 5}>
              <AccordionHeader onClick={() => handleOpen(5)}>
                <h5 className="text-black">
                  {" "}
                  How Long Does It Take For Funds To Be Transferred Through
                  Payripe ?
                </h5>
              </AccordionHeader>
              <AccordionBody>
                <h5 className="text-xl font-normal">
                  {" "}
                  The time it takes for funds to be transferred through Payripe
                  may vary depending on the payment method and the processing
                  time of the receiving bank or financial institution. In
                  general, most transactions are processed within a few days.
                </h5>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 6}>
              <AccordionHeader onClick={() => handleOpen(6)}>
                <h5 className="text-black">
                  {" "}
                  What If I have An Issue With Payripe Transaction ?
                </h5>
              </AccordionHeader>
              <AccordionBody>
                <h5 className="text-xl font-normal">
                  {" "}
                  If you have an issue with a Payripe transaction, you can
                  contact Payripe's customer support team for assistance. They
                  can help you resolve any issues related to payments, refunds,
                  chargebacks, and other transaction-related problems.
                </h5>
              </AccordionBody>
            </Accordion>
          </div>
        </div>

        <section className="mb-32 text-gray-800 text-center">
          <div className="flex flex-wrap justify-center">
            <div className="grow-0 shrink-0 flex-basis w-full lg:w-6/12 px-3">
              <div className="p-4 bg-green-600 rounded-full shadow-lg inline-block mb-6">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  className="w-5 h-5 text-white"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                  ></path>
                </svg>
              </div>

              <h2 className="text-3xl prtextcolor font-bold mb-6">
                Subscribe to our Newsletter
              </h2>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="md:flex md:justify-content flex-row">
                      <Field
                        name="email"
                        type="email"
                        className="form-control block w-full px-4 py-2 mb-2 md:mb-0 md:mr-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                        placeholder="Enter your email"
                      />

                      <button
                        type="submit"
                        className="inline-block px-7 py-3 bg-green-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Subscribe
                      </button>
                    </div>

                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Prhome;
