import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import thunk from "redux-thunk";
import { getUser } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faUser, faKey, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";
import { LoginRequest } from "../Apis/DataService";

const Login = () => {
  let navigate = useNavigate();

  const [loginError, setloginError] = useState("");
  const [email, setemail] = useState("");
  const [verificationEmailShow, setverificationEmailShow] = useState(false);
  const [validationError, setvalidationError] = useState({});

  const initialValues = {
    email: "",
    password: "",
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const dispatch = useDispatch();

  //submitform
  const submitForm = async (values, { resetForm }) => {
    console.log(values);
    // resetForm(initialValues);
    setemail(values.email);

    const returnData = await LoginRequest(values);
    console.log(returnData);
    if (returnData.success == true) {
      ToastSuccessNotifications("Logging In");

      localStorage.setItem("user", JSON.stringify(returnData.data));

      dispatch(getUser(returnData.data));

      navigate("/dashboard");
    } else if (returnData.success == false) {
      console.log(returnData.error);
      setloginError(returnData.error);
      ToastErrorNotifications(returnData.error);

      if (returnData.error == "Email Not Verified") {
        setverificationEmailShow(true);
      }

      console.log(loginError);
    }

    console.log("cookie:", localStorage.getItem("user"));

    console.log(returnData);
  };

  return (
    <section class="bg-gray-50">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
             Admin
            </h1>

            <Formik
              initialValues={initialValues}
              validationSchema={SignInSchema}
              onSubmit={submitForm}
            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;

                return (
                  <div className="mt-16">
                    <Form>
                      <label className="relative flex">
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                          placeholder="Email"
                        />
                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 transition-colors duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                        </span>
                      </label>
                      {errors.email && touched.email && (
                        <span className="text-tiny+ text-error">
                          {errors.email}
                        </span>
                      )}
                      <label className="relative mt-4 flex">
                        <Field
                          type="password"
                          name="password"
                          id="password"
                          className="form-input peer  w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                          placeholder="Password"
                        />
                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 transition-colors duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                        </span>
                      </label>
                      {errors.password && touched.password && (
                        <span className="text-tiny+ text-error">
                          {errors.password}
                        </span>
                      )}

                      <ToastContainer />
                      <div className="mt-4 flex items-center justify-between space-x-2">
                        <label className="inline-flex items-center space-x-2">
                          <input
                            className="form-checkbox is-outline  h-5 w-5 rounded border-slate-400/70 bg-slate-100 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-500 dark:bg-navy-900 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                            type="checkbox"
                          />
                          <span className="line-clamp-1">Remember me</span>
                        </label>
                        
                      </div>

                      <button
                        type="submit"
                        // disabled={!(dirty && isValid)}
                        className="mt-3 w-full btn border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90 dark:border-accent dark:text-accent-light dark:hover:bg-accent dark:hover:text-white dark:focus:bg-accent dark:focus:text-white dark:active:bg-accent/90"
                      >
                        Sign In
                      </button>
                    </Form>
                    
                   
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
