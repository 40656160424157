import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
const Software = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Expertly Crafted Websites Tailored To Your Business Needs
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Expert Team Creates Custom Websites Tailored To Your Business Needs, With A Focus On Aesthetics, Functionality.
              </h5>
              <a href="https://pg.payripe.in/register" target="_blank">
              <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
              </button>
              </a>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/softwaredev.png"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Solutions To Streamline Your Business
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Custom Software Development Services Are Designed To Provide Innovative Solutions That Streamline Your Business Operations And Enhance Efficiency. We Work Closely With You To Understand Your Unique Business Needs.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
               Customized Development
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
              User-Friendly Interfaces
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                Integration With Existing Systems
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                 Data-Driven Insights
                </h5>
              </div>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/softwarecodetestingcuate.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className=""
              alt=""
              src="./public/appdevelopmentamico.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
              Modernize Your Business With Us
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
               Our Digital Transformation Services Are Focused On Helping You Modernize Your Business Processes And Stay Ahead Of The Competition. We Provide Custom Software Solutions That Ultimately Helping You Achieve Your Business Goals.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
              Cloud-Based Solutions
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                Cutting-Edge Technology
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
               User-Friendly Interfaces
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                Customizable Solutions
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Software;
