import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="prbox ">
        <div className="flex justify-center py-8">
          <h5
            className="md:text-5xl  text-2xl font-bold  text-white  "
            style={{ maxWidth: "750px" }}
          >
            Privacy Policy
          </h5>
        </div>
      </div>

      <div className="mt-8 mb-40">
        <h5 className="text-black font-semibold text-lg leading-10">
          At Payripe, we respect your privacy and are committed to protecting
          your personal information. This privacy policy explains how we
          collect, use, disclose, and protect the personal information of our
          customers, visitors to our website, and users of our products and
          services. Information We Collect: We collect personal information that
          you provide to us, such as your name, email address, phone number,
          billing information, and other information necessary to process your
          transactions. We also collect information about your use of our
          website and products, including your IP address, browser type, and
          pages visited. How We Use Your Information: We use your personal
          information to provide you with the services and products you request,
          to process your transactions, to communicate with you, and to improve
          our products and services. We may also use your information for
          marketing purposes, but we will always give you the opportunity to
          opt-out of receiving such communications. How We Share Your
          Information: We may share your personal information with third-party
          service providers that help us to provide our products and services,
          such as payment processors and shipping companies. We may also share
          your information with our affiliates and partners for marketing
          purposes. We will not sell your personal information to third parties.
          How We Protect Your Information: We take reasonable measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, and destruction. We use industry-standard
          security measures, such as encryption, to protect your information
          during transmission. We also restrict access to your information to
          only those employees who need it to perform their job functions. Your
          Rights: You have the right to access, update, and delete your personal
          information. You may also opt-out of receiving marketing
          communications from us at any time. To exercise your rights, please
          contact us at info@payripe.com. Changes to Our Privacy Policy: We may
          update this privacy policy from time to time. We will notify you of
          any material changes to this policy by posting the updated policy on
          our website and sending you an email notification. Contact Us: If you
          have any questions or concerns about our privacy policy, please
          contact us at info@payripe.com. Effective Date: This privacy policy is
          effective as of April 5, 2023.
        </h5>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
