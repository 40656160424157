import React, { useState, useRef, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import { addBlog, getNewsCategory } from "../Apis/AuthenticatedDataService";
import { ToastSuccessNotifications } from "../Notifications/ToastNotifications";

const AddBlog = (props) => {
  const [categories, setCategories] = useState("");
  const editor = useRef(null);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");

  const getData = async () => {
    const getCategories = await getNewsCategory(0, 0, "");
    setCategory(getCategories.data[0]._id);
    setCategories(getCategories.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeCategory = (e) => {
    setCategory(e.target.value);
  };

  const onChangeImage = (e) => {
    console.log(e.target.files[0]);
    setImage(e.target.files[0]);
  };
  const onChangeContent = (newcontent) => {
    setContent(newcontent);
  };

  const onSubmit = async () => {
    if (title == "") {
      setTitleError("Please fill Title");
    }

    console.log(content);

    if (content == "") {
      setContentError("Please fill Blog Content");
    }

    if (title != "" && content != "") {
      let formData = new FormData();

      // Update the formData object
      formData.append("name", title);
      formData.append("category", category);
      formData.append("image", image);
      formData.append("content", content);

      const sendBlog = await addBlog(formData);
      ToastSuccessNotifications(sendBlog.message);

      setTitle("");
      setImage("");
      setContent("");

      console.log("submit");
    } else {
      console.log("error");
    }
  };

  return (
    <div className="relative overflow-x-auto mx-8">
      <div
        className="text-yellow bg-bloodred w-full rounded-lg text-center"
        style={{ fontSize: "30px", fontWeight: "700" }}
      >
        Add Blog
      </div>

      <div className="mb-4 mt-3">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Title
        </label>
        <input
          type="text"
          value={title}
          onChange={(e) => {
            onChangeTitle(e);
          }}
          className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
          placeholder=""
        />
        {titleError != "" && <h5 className="text-red-500">{titleError}</h5>}
      </div>

      <div className="mb-4 mt-3">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Category
        </label>
        <select
          name=""
          className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
          id=""
          onChange={(e) => {
            onChangeCategory(e);
          }}
        >
          {categories.length > 0 &&
            categories.map((item) => (
              <option value={item._id} key={item._id}>{item.name}</option>
            ))}
        </select>
        {titleError != "" && <h5 className="text-red-500">{titleError}</h5>}
      </div>

      <div className="mb-4 mt-3">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Banner Image
        </label>
        <input
          type="file"
          id="file"
          onChange={(e) => {
            onChangeImage(e);
          }}
          className="grow form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
          placeholder=""
        />
      </div>

      <div className="mb-4 mt-3">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Blog Content
        </label>
        <div style={{ height: "100%" }}>
          <JoditEditor
            ref={editor}
            value={content}
            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {
              onChangeContent(newContent);
            }}
          />
        </div>
        {contentError != "" && <h5 className="text-red-500">{contentError}</h5>}
      </div>

      <div className="text-center mt-3 mb-1">
        <button
          className="btn-yellow h-10 mt-3 px-5 py-2"
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default AddBlog;

function handleChange(content: any) {
  throw new Error("Function not implemented.");
}
