import React from "react";
import { Link } from "react-router-dom";

const Footer = (prop) => {
  return (
    <div className="w-full footer"  >
      <footer className=" bg-red-600  shadow  ">
        <div className="w-full mx-auto container md:p-6 p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-white sm:text-center ">
          {prop.brand.domain != null && prop.brand.domain.copyright}
          </span>
          <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500  sm:mt-0">
            <li>
              
            </li>
           
           
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
