import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
const HomeLayout = () => {
  const [brandinfo, setbrandinfo] = useState("");
  const DomainInfo = useSelector((state) => state.domain);

  

  useEffect(() => {
    
    setbrandinfo(DomainInfo);
  }, []);

  return (
    <div>
      <body className="flex flex-col h-screen">
        <header className=" text-white ">
          {" "}
          <Header brand={DomainInfo}/>
        </header>

        <main className="flex-1">
          <Outlet />
        </main>

        <footer className=" text-white ">
          <Footer brand={DomainInfo}/>
        </footer>
      </body>
    </div>
  );
};

export default HomeLayout;
