import {
    Sidebar,
    SubMenu,
    Menu,
    MenuItem,
    useProSidebar,
  } from "react-pro-sidebar";
  import { Outlet, useLocation } from "react-router-dom";
  import { Link } from "react-router-dom";
  import { faHome } from "@fortawesome/free-solid-svg-icons";
  import {
    faChartPie
  } from "@fortawesome/free-solid-svg-icons";
  import { IoNewspaperOutline } from "react-icons/io5";
  import { RiAdvertisementFill,RiCalendarEventLine,RiTeamFill,RiDashboard2Line,RiMailLine } from "react-icons/ri";
  import { MdOutlineBrandingWatermark,MdOutlinePolicy,MdLogout } from "react-icons/md";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { FaCommentDots } from "react-icons/fa";
  import React from "react";
  import { useState } from "react";
  import sidebarcss from "./sidebar.css";


const InnerSidebar = () => {
  const location = useLocation();
    return (
       
              <Sidebar  className="bg-white h-screen">
              <Menu
                renderExpandIcon={({ open }) => <span>{open ? "-" : "+"}</span>}
              >
                

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/dashboard"
                      ? " text-danger "
                      : ""
                  }`}
                  routerLink={<Link to="/dashboard" />}
                  title="Dashboard"
                  icon={
                    <RiDashboard2Line
                      className={` ${
                        window.location.pathname === "/dashboard"
                          ? " text-danger text-xl"
                          : ""
                      }`}
                      
                    />
                  }
                >
                  {" "}
                  Dashboard
                </MenuItem>

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/categories"
                      ? " text-danger "
                      : ""
                  }`}
                  routerLink={<Link to="/categories" />}
                  title="Categories"
                  icon={
                    <FontAwesomeIcon
                      className={` ${
                        window.location.pathname === "/categories"
                          ? "bg-zinc-300 px-3 py-3 rounded-lg text-danger text-xl"
                          : ""
                      }`}
                      icon={faChartPie}
                    />
                  }
                >
                  {" "}
                   Categories
                </MenuItem>

                
                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/blogs"
                      ? " text-danger "
                      : ""
                  }`}
                  routerLink={<Link to="/blogs" />}
                  title="Blogs"
                  icon={
                    <IoNewspaperOutline
                      className={` ${
                        window.location.pathname === "/blogs"
                          ? " text-danger text-xl"
                          : ""
                      }`}
                     
                    />
                  }
                >
                  {" "}
                  Blogs
                </MenuItem>

                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/images"
                      ? " text-danger "
                      : ""
                  }`}
                  routerLink={<Link to="/images" />}
                  title="images"
                  icon={
                    <MdOutlineBrandingWatermark
                      className={` ${
                        window.location.pathname === "/images"
                          ? " text-danger text-xl"
                          : ""
                      }`}
                    />
                  }
                >
                  {" "}
                  Images
                </MenuItem>


                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/brand"
                      ? " text-danger "
                      : ""
                  }`}
                  routerLink={<Link to="/brand" />}
                  title="Brand"
                  icon={
                    <MdOutlineBrandingWatermark
                      className={` ${
                        window.location.pathname === "/brand"
                          ? " text-danger text-xl"
                          : ""
                      }`}
                    />
                  }
                >
                  {" "}
                  Brand
                </MenuItem>

              



                <MenuItem
                  className={`font-medium ${
                    window.location.pathname === "/logout"
                      ? " text-danger "
                      : ""
                  }`}
                  routerLink={<Link to="/logout" />}
                  title="Logout"
                  icon={
                    <MdLogout
                      className={` ${
                        window.location.pathname === "/logout"
                          ? " text-danger text-xl"
                          : ""
                      }`}
                      
                    />
                  }
                >
                  {" "}
                  Logout
                </MenuItem>


                
              </Menu>
            </Sidebar>
       
    );
};

export default InnerSidebar;