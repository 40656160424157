import Dropdown from "react-bootstrap/Dropdown";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProSidebar } from "react-pro-sidebar";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import React,{useState,useEffect} from "react";
function Topbar() {
  const location = useLocation();
  
  const { collapseSidebar } = useProSidebar();

  return (
    <div className="flex border-b-2 border-slate-200 mb-3 pb-3 bg-white">
      {location.pathname != '/home' &&  <button
        className="rounded-lg  px-5 mt-2  ml-3"
        onClick={() => collapseSidebar()}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>}
     
      <div className="w-full flex justify-end items-center mt-2 ">
       
      </div>
    </div>
  );
}

export default Topbar;
