import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
const Invoice = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Acquirer Management</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto ">
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Get Efficient Acquirer Management For Secure Payment Processing
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Reseller Panel Feature Allows You To Manage Multiple
                Merchants Or Sub-Resellers Within A Single Platform.
              </h5>
              <a href="https://pg.payripe.in/register" target="_blank">
              <button className="ml-8 mt-8 border border-2 rounded border-white text-white  px-3 py-3">
                  Sign Up Now
              </button>
              </a>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/officemanagementcuate.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Advanced Acquirer Management System
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Advanced Acquirer Management System Provides A Comprehensive
                Platform For Managing Multiple Acquirers And Payment Channels In
                Real-Time. With Our System, You Can Streamline The Payment
                Process.
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
                  Flexible Payment Options
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />
                  Multiple Payment Methods
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                  Real-Time Monitoring
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />{" "}
                  Secure Data Encryption
                </h5>
              </div>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/officemanagementbro.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img className="" alt="" src="./public/accepttasksbro.svg" />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Manage Multiple Acquirers & Payment
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Payment Gateway Website Allows You To Efficiently Manage
                Multiple Acquirers And Payment Channels In A Single Platform.
                Our Advanced Acquirer Management System Simplifies The Payment
                Process
              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter1outline.svg"
                    alt=""
                  />{" "}
                  Streamlining Payment Processing
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter2outline.svg"
                    alt=""
                  />{" "}
                  Customizable Rules And Filters
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter3outline.svg"
                    alt=""
                  />{" "}
                  User-Friendly Platform
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">
                  <img
                    className="mr-1"
                    src="./public/materialsymbolscounter4outline.svg"
                    alt=""
                  />
                  Customizable Payment Options
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
