import React, { useState, useEffect } from "react";
import TopStories from "./HomeComponents/TopStories";
import SocialMedia from "./HomeComponents/SocialMedia";
import LatestBlog from "./HomeComponents/LatestBlog";


const Home = () => {
  
  return (
    <div className=" " style={{margin:'60px 100px 100px 100px'}}>
     
      <div className="grid grid-cols-5 md:grid-cols-8 gap-8 mt-3">
        <div className=" col-span-5">
      <TopStories />
        </div>
        <div className=" col-span-5 md:col-span-3 ">
          <LatestBlog/>
        <SocialMedia/>
        </div>
      </div>
    </div>
  );
};

export default Home;
