import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="prbox ">
        <div className="flex justify-center py-8">
          <h5
            className="md:text-5xl  text-2xl font-bold  text-white  "
            style={{ maxWidth: "750px" }}
          >
            Terms of Use
          </h5>
        </div>
      </div>

      <div className="mt-8 mb-40">
        <h5 className="text-black font-semibold text-lg leading-10">
          Welcome to Payripe, a payment gateway company that offers a wide range
          of services including website design, e-commerce website, software
          development, payment software, mobile app development, digital
          marketing, content writing, graphic design, bulk SMS/voice/WhatsApp,
          automation testing, and products such as Payin, Payout, Invoice
          Payment, ATM, White Label Platform, Reseller Panel, and Acquirer
          Management. By accessing or using any of our services, you agree to be
          bound by the following terms and conditions: 1. GENERAL TERMS 1.1.
          Definitions In these Terms and Conditions, the following definitions
          apply: "Payripe," "we," "us," or "our" refers to Payripe, a payment
          gateway company. "Service" or "Services" means any of the services
          provided by Payripe, including but not limited to website design,
          e-commerce website, software development, payment software, mobile app
          development, digital marketing, content writing, graphic design, bulk
          SMS/voice/WhatsApp, automation testing, and products such as Payin,
          Payout, Invoice Payment, ATM, White Label Platform, Reseller Panel,
          and Acquirer Management. "User," "you," or "your" refers to any person
          or entity that accesses or uses our Services. 1.2. Acceptance of Terms
          By accessing or using any of our Services, you agree to be bound by
          these Terms and Conditions, including our Privacy Policy. If you do
          not agree to these Terms and Conditions, you should not use our
          Services. 1.3. Modifications to Terms We reserve the right to modify
          these Terms and Conditions at any time without prior notice. Your
          continued use of our Services after any modifications to these Terms
          and Conditions constitutes your acceptance of the modified Terms and
          Conditions. 1.4. Eligibility You must be at least 18 years old to use
          our Services. By accessing or using our Services, you represent and
          warrant that you are at least 18 years old and have the legal capacity
          to enter into these Terms and Conditions. 2. SERVICES 2.1. Description
          of Services Payripe provides a range of services including website
          design, e-commerce website, software development, payment software,
          mobile app development, digital marketing, content writing, graphic
          design, bulk SMS/voice/WhatsApp, automation testing, and products such
          as Payin, Payout, Invoice Payment, ATM, White Label Platform, Reseller
          Panel, and Acquirer Management. The description of each Service is
          provided on our website. 2.2. Use of Services You may use our Services
          only for lawful purposes and in accordance with these Terms and
          Conditions. You agree not to use our Services: In any way that
          violates any applicable federal, state, local, or international law or
          regulation. To engage in any activity that interferes with or disrupts
          our Services or servers or networks connected to our Services. To
          impersonate any person or entity or misrepresent your affiliation with
          a person or entity. To engage in any other conduct that restricts or
          inhibits anyone's use or enjoyment of our Services. 2.3. Availability
          of Services We make no representation that our Services will be
          available at all times or that they will be uninterrupted or
          error-free. We reserve the right to modify, suspend, or discontinue
          any Service at any time without prior notice. 2.4. Fees and Payment
          The fees for our Services are provided on our website. You agree to
          pay all fees and charges associated with your use of our Services.
          Payment must be made through our payment gateway using a valid payment
          method. All payments are non-refundable. 3. PRODUCTS 3.1. Description
          of Products Payripe offers various products such as Payin, Payout,
          Invoice Payment, ATM, White Label Platform, Reseller Panel, and
          Acquirer Management. The description of each product is provided on
          our website. 3.2. Use of Products You may use our products only for
          lawful purposes and in accordance with these Terms and Conditions. You
          agree not to use our products: In any way that violates any applicable
          federal, state, local, or international law or regulation. To engage
          in any activity that interferes with or disrupts our products or
          servers or networks connected to our products. To impersonate any
          person or entity or misrepresent your affiliation with a person or
          entity. To engage in any other conduct that restricts or inhibits
          anyone's use or enjoyment of our products. 3.3. Availability of
          Products We make no representation that our products will be available
          at all times or that they will be uninterrupted or error-free. We
          reserve the right to modify, suspend, or discontinue any product at
          any time without prior notice. 3.4. Fees and Payment The fees for our
          products are provided on our website. You agree to pay all fees and
          charges associated with your use of our products. Payment must be made
          through our payment gateway using a valid payment method. All payments
          are non-refundable. 4. INTELLECTUAL PROPERTY 4.1. Ownership All
          content and materials provided as part of our Services and products,
          including but not limited to text, graphics, logos, images, videos,
          software, and other materials, are the property of Payripe or our
          licensors and are protected by copyright, trademark, patent, trade
          secret, and other intellectual property or proprietary rights laws.
          4.2. Use of Materials You may use the materials provided as part of
          our Services and products only for your personal and non-commercial
          use. You may not copy, reproduce, modify, distribute, display,
          perform, or transmit any content or materials provided as part of our
          Services and products for any commercial purpose without our prior
          written consent. 4.3. Trademarks The Payripe name and logo are
          trademarks of Payripe. You may not use our trademarks without our
          prior written consent. 5. CONFIDENTIALITY 5.1. Definition
          "Confidential Information" means any information disclosed by one
          party to the other party, either directly or indirectly, in writing,
          orally, or by inspection of tangible objects, that is designated as
          confidential or that should be reasonably understood to be
          confidential given the nature of the information and the circumstances
          of disclosure. 5.2. Obligations Each party agrees to maintain the
          confidentiality of the other party's Confidential Information and to
          use such Confidential Information only for the purposes of fulfilling
          its obligations under these Terms and Conditions. Each party shall
          take reasonable measures to protect the other party's Confidential
          Information from unauthorized disclosure or use. 6. DISCLAIMER OF
          WARRANTIES 6.1. No Warranty Payripe makes no representation or
          warranty, express or implied, regarding the suitability, reliability,
          availability, timeliness, quality, or accuracy of our Services and
          products. Our Services and products are provided "as is" and "as
          available" without warranty of any kind. 6.2. Use at Your Own Risk You
          use our Services and products at your own risk. Payripe shall not be
          liable for any damages or losses of any kind arising from or related
          to your use of our Services and products. 7. LIMITATION OF LIABILITY
          7.1. Exclusion of Damages In no event shall Payripe be liable for any
          direct, indirect, incidental, special, or consequential damages
          arising from or related to your use of our Services and products,
          including but not limited to any loss of profits, revenue, business,
          or data. 7.2. Total Liability Payripe's total liability to you for any
          and all claims arising from or related to your use of our Services and
          products shall not exceed the amount paid by you to Payripe for such
          Services and products. 8. INDEMNIFICATION You agree to indemnify,
          defend, and hold harmless Payripe and its affiliates, officers,
          directors, employees, agents, licensors, and suppliers from and
          against any claims, liabilities, damages, judgments, awards, losses,
          costs, expenses, or fees (including reasonable attorneys' fees)
          arising from or related to your use of our Services and products, your
          violation of these Terms and Conditions, or your violation of any
          rights of another party. 9. TERMINATION 9.1. Termination by Payripe
          Payripe may terminate these Terms and Conditions and your access to
          our Services and products at any time without prior notice, for any
          reason or no reason, including but not limited to your violation of
          these Terms and Conditions. 9.2. Termination by You You may terminate
          these Terms and Conditions at any time by ceasing to use our Services
          and products. 9.3. Effect of Termination Upon termination of these
          Terms and Conditions, you shall immediately cease all use of our
          Services and products. The provisions of these Terms and Conditions
          that by their nature should survive termination shall survive
          termination, including but not limited to Sections 4, 5, 6, 7, 8, and
          10. 10. MISCELLANEOUS 10.1. Governing Law These Terms and Conditions
          shall be governed by and construed in accordance with the laws of
          [insert applicable jurisdiction], without giving effect to any
          principles of conflicts of law. 10.2. Dispute Resolution Any dispute
          arising from or related to these Terms and Conditions shall be
          resolved through binding arbitration in accordance with the rules of
          [insert applicable arbitration association]. The arbitration shall be
          conducted in [insert applicable location]. The award of the arbitrator
          shall be final and binding and may be entered in any court of
          competent jurisdiction. 10.3. Entire Agreement These Terms and
          Conditions constitute the entire agreement between Payripe and you
          regarding the use of our Services and products and supersede all prior
          agreements and understandings, whether written or oral. 10.4. Waiver
          The failure of Payripe to enforce any right or provision of these
          Terms and Conditions shall not be deemed a waiver of such right or
          provision. 10.5. Severability If any provision of these Terms and
          Conditions is held to be invalid or unenforceable, such provision
          shall be struck and the remaining provisions shall be enforced. 10.6.
          Assignment You may not assign these Terms and Conditions, in whole or
          in part, without the prior written consent of Payripe. Payripe may
          assign these Terms and Conditions, in whole or in part, without your
          consent. By using our Services and products, you acknowledge that you
          have read, understood, and agree to be bound by these Terms and
          Conditions. If you do not agree to these Terms and Conditions, you may
          not use our Services and products.
        </h5>
      </div>
    </div>
  );
};

export default TermsAndConditions;
