import React from "react";
import OuterSidebar from "./OuterSidebar";
import { Outlet, useLocation } from "react-router-dom";
import InnerSidebar from "./InnerSidebar";
import Topbar from './TopBar'
import Footer from "./Footer";
const Layout = () => {
  return (
    
      <div className="flex">
        
        <aside className="h-screen sticky top-0" style={{  }}> <InnerSidebar /> </aside>
       
        <main className="w-full">
        <aside className="w-full sticky top-0" style={{ zIndex:'998' }}><Topbar/></aside>
            <Outlet/>
            
        </main>
    
      </div>
   
  );
};

export default Layout;
