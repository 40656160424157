import {configureStore} from '@reduxjs/toolkit';
import  authSlice  from './authSlice';
import domainSlice from './domainSlice';


const store = configureStore({
    reducer:{
        user: authSlice,
        domain:domainSlice
    }
 
})

export default store;