import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBrandInfo } from "../Apis/DataService";
import { getDomain } from "../../store/domainSlice";
import { Outlet } from "react-router-dom";

const BrandInfo = () => {
  const dispatch = useDispatch();
  const [Brand, setBrand] = useState("");

  const getData = async () => {
    const fetchBrand = await GetBrandInfo();
    setBrand(fetchBrand.data);
    dispatch(getDomain(fetchBrand.data));
  };

  const DomainInfo = useSelector((state) => state.domain);

  useEffect(() => {
    getData();
  }, []);

  return <Outlet />;
};

export default BrandInfo;
