import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAllBlogsByCategory } from "../Apis/DataService";
import PaginatedItems from "../pagination/pagination";
const VideosByCategory = () => {
  const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const { id } = useParams();
  const [blogs, setBlogs] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(8);
  const [count, setCount] = useState("");

  const getData = async (skip, limit, id) => {
    const fetchBlogs = await getAllBlogsByCategory(skip, limit, id);

    setBlogs(fetchBlogs.data);
    setCount(fetchBlogs.count);
  };

  const fetchdata = (start, end) => {
    setSkip(end);
    setLimit(start);
    getData(end, start, id);
  };

  useEffect(() => {
    getData(skip, limit, id);
  }, [id]);
  return (
    <div style={{margin:'60px 100px 100px 100px'}}>
      <div>
        <h5 className="text-xl font-bold text-black">Blogs</h5>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
          {blogs.length > 0 ?
            blogs.slice(0, 10).map((item) => (
              <Link key={item._id} to={`/blog/${item._id}`}>
                <div>
                  <div className=" rounded overflow-hidden shadow-lg">
                    <img
                      className="w-full object-fill h-64"
                      src={`${backendUrl}/blog/${item.image}`}
                      alt="Sunset in the mountains"
                    />
                    <div className="px-6 py-0">
                      <div className="font-bold text-black text-sm text-red-400">
                        {item.category.name}
                      </div>
                    </div>
                    <div className="px-6 py-1">
                      <div className="font-bold text-black text-lg mb-2">
                        {item.name}
                      </div>
                    </div>
                    <div className="px-6">
                      {moment(item.date).format("MMMM Do YYYY, h:mm:ss a")}
                    </div>
                  </div>
                </div>
              </Link>
            )):
            <div>
              <h5 className="text-lg"> No blogs in this category</h5> 
            </div>
            }
        </div>
        <div className="flex justify-center mt-5">
          <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
        </div>
      </div>
    </div>
  );
};

export default VideosByCategory;
