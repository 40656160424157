import React, { useState, useEffect } from "react";
import { getLatestBlogs } from "../../Apis/DataService";
import moment from "moment";
import { Link } from "react-router-dom";

const LatestBlog = () => {
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
  const [blogs, setBlogs] = useState("");

  const getData = async () => {
    const latestBlogs = await getLatestBlogs();

    setBlogs(latestBlogs.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return <div>
     <div>
        <h5 className="text-xl font-bold text-black">Latest Blogs</h5>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-1 gap-3 mt-3">
          {blogs.length > 0 &&
            blogs.map((item) => (
              <Link key={item._id} to={`/blog/${item._id}`}>
                <div className="flex">
                  <div className=" rounded overflow-hidden shadow-lg w-full">
                    
                     <div className="px-6 py-0">
                      <div className="font-bold text-black text-sm text-red-400">
                        {item.category.name}
                      </div>
                    </div>
                    <div className="px-6 py-1">
                      <div className="font-bold text-black text-lg mb-2">
                        {item.name}
                      </div>
                    </div>
                  </div>

                  <div>
                  <img
                      className="w-40 object-fill h-28"
                      src={`${backendUrl}/blog/${item.image}`}
                      alt="Sunset in the mountains"
                    />
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
  </div>;
};

export default LatestBlog;
